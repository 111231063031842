import { NavLink } from 'react-router-dom'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import {
  MdOutlineAccountCircle,
  MdOutlineCategory,
  MdOutlineCloudUpload,
  MdOutlineCreditCard,
  MdOutlineDashboard,
  MdOutlineGroup,
  MdOutlineSell,
  MdOutlineStarBorder,
  MdOutlineStore,
  MdOutlineWorkOutline,
} from 'react-icons/md'
import { config } from '@/config'
import { useDevice } from '@/hooks/use-device'
import { useViewer } from '../viewer-context-provider'
import './index.scss'

interface Props {
  children?: React.ReactNode
}

export function FullLayout({ children }: Props) {
  const viewer = useViewer()
  const device = useDevice()

  const otherInstances = (viewer.user?.instances || []).filter(
    ({ id }) => id !== viewer.instance?.id
  )

  const handleSignOut = async () => {
    await fetch(config.authSessionUrl, {
      method: 'DELETE',
      credentials: 'include',
    })
    window.location.replace('/login')
  }

  const switchInstance = async (instanceId: string) => {
    await fetch(config.authRefreshUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ instanceId }),
    })
    window.location.reload()
  }

  return (
    <div className={`full-layout layout--${device}`}>
      <nav className="full-layout--nav">
        <NavLink end to="/">
          <MdOutlineDashboard />
          Dashboard
        </NavLink>
        <NavLink to="/users">
          <MdOutlineGroup />
          Brukere
        </NavLink>
        <NavLink to="/clients">
          <MdOutlineWorkOutline />
          Kunder
        </NavLink>
        <NavLink to="/suppliers">
          <MdOutlineStore />
          Leverandører
        </NavLink>
        <NavLink to="/categories">
          <MdOutlineCategory />
          Kategorier
        </NavLink>
        <NavLink to="/specialists">
          <MdOutlineStarBorder />
          Spesialister
        </NavLink>
        <NavLink to="/documents">
          <MdOutlineCloudUpload />
          Dokumenter
        </NavLink>
        {viewer.instance?.id !== 'se' && viewer.isAdmin && (
          <NavLink to="/invoices">
            <MdOutlineCreditCard />
            Faktura
          </NavLink>
        )}
        {viewer.isAdmin && (
          <NavLink to="/offers">
            <MdOutlineSell />
            Benchmarkbase
          </NavLink>
        )}
      </nav>

      <header className="full-layout--header">
        <Menu>
          <MenuButton>
            <span className="header--current-user">
              <div>
                <span className="header--current-user--user">
                  {viewer.user?.name || ''}
                </span>
                <span className="header--current-user--instance">
                  {viewer.instance?.name || ''}
                </span>
              </div>
              <MdOutlineAccountCircle />
            </span>
          </MenuButton>
          <MenuList>
            {otherInstances.map((instance) => (
              <MenuItem
                key={instance.id}
                onClick={() => switchInstance(instance.id)}
              >
                {instance.name}
              </MenuItem>
            ))}
            <MenuItem onClick={handleSignOut}>Logg ut</MenuItem>
          </MenuList>
        </Menu>
      </header>

      <main className="full-layout--main">{children}</main>
    </div>
  )
}
