import { Link as RouteLink, Outlet } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Button, ButtonGroup, Link } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { useQueryParams } from '@/hooks/use-query-params'
import { usePagination } from '@/hooks/use-pagination'
import { FullLayout } from '@/components/full-layout'
import { ContainerBox } from '@/components/container-box'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/components/table'
import { Row, Rows } from '@/components/rows'
import { Heading } from '@/components/heading'
import { DownloadExportButton } from '@/components/download-export-button'
import { SearchButtonField } from '@/components/search-button-field'
import { SortDirectionLink } from '@/components/sort-direction-link'
import { LoadingSection } from '@/components/loading-section'

export const clientsDocument = graphql(`
  query Clients($input: ConnectionInputArgs!) {
    clients(input: $input) {
      totalCount
      results {
        id
        name
        publicRef
        status
        address {
          postalArea
        }
        user {
          id
          name
        }
      }
    }
  }
`)

export function ClientsRoute() {
  const [queryParams, setQueryParams] = useQueryParams()
  const { Pagination, variables } = usePagination()
  const { data, loading, error } = useQuery(clientsDocument, {
    variables: {
      input: {
        ...variables,
        ...queryParams,
        page: queryParams.page ? Number(queryParams.page) : undefined,
      },
    },
  })
  const clients = data?.clients.results || []
  const totalCount = data?.clients.totalCount || 0

  if (error) throw error

  return (
    <FullLayout>
      <ContainerBox>
        <Rows>
          <Row>
            <Heading
              title="Kunder"
              actions={
                <>
                  <SearchButtonField
                    placeholder="Søk på navn eller organisasjonsnummer"
                    query={queryParams.query}
                    onChange={(query) => setQueryParams({ query })}
                  />
                  <ButtonGroup>
                    <DownloadExportButton
                      label="Last ned (Excel)"
                      file="clients.xlsx"
                    />
                    <Button
                      as={RouteLink}
                      to="new"
                      size="sm"
                      colorScheme="blue"
                    >
                      Ny kunde +
                    </Button>
                  </ButtonGroup>
                </>
              }
            />
          </Row>
          <Row>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <SortDirectionLink field="client.publicRef" label="#" />
                  </Th>
                  <Th>
                    <SortDirectionLink field="client.name" label="Kunde" />
                  </Th>
                  <Th>
                    <SortDirectionLink field="client.status" label="Status" />
                  </Th>
                  <Th>
                    <SortDirectionLink field="user.name" label="Rådgiver" />
                  </Th>
                  <Th>Poststed</Th>
                </Tr>
              </Thead>
              <Tbody>
                {clients.map((client) => (
                  <Tr key={client.id}>
                    <Td>{client.publicRef}</Td>
                    <Td>
                      <Link as={RouteLink} to={client.id}>
                        {client.name}
                      </Link>
                    </Td>
                    <Td>{client.status}</Td>
                    <Td>{client.user?.name || '-'}</Td>
                    <Td>{client.address.postalArea}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {loading && <LoadingSection />}
          </Row>
          {totalCount > variables.limit && (
            <Row>
              <Pagination total={totalCount} />
            </Row>
          )}
        </Rows>
      </ContainerBox>

      <Outlet />
    </FullLayout>
  )
}
