import { format as dateFormatter } from 'date-fns'
import { nb } from 'date-fns/locale'
import parsePhoneNumber from 'libphonenumber-js'

const locales = { nb }

export function formatCurrency(number: number, suffix = '') {
  const value = Intl.NumberFormat('nb', {
    currency: 'NOK',
    style: 'currency',
    currencyDisplay: 'code',
  })
    .format(number)
    .replace('NOK', '')
    .trim()
  return [value, suffix].join(' ').trim()
}

export function formatNumber(input: number | string, decimals = 2) {
  const [left, right] = Number(input).toFixed(decimals).split('.')

  return [left.replace(/\B(?=(\d{3})+(?!\d))/g, ' '), right].join(
    decimals ? ',' : ''
  )
}

export function formatPhoneNumber(number: string) {
  return parsePhoneNumber(number, 'NO')?.formatInternational() ?? number
}

export function formatDate(date: Date | string) {
  return dateFormatter(new Date(date), 'P', { locale: locales.nb })
}

export function formatDateTime(date: Date | string) {
  return dateFormatter(new Date(date), 'Pp', { locale: locales.nb })
}
