import cn from 'classnames'
import './index.scss'

type Props = {
  children?: React.ReactNode
  center?: boolean
}

export function ActionsBar({ children, center }: Props) {
  return <div className={cn('actions-bar', { center })}>{children}</div>
}
