export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  const ret = {} as {
    [K in keyof typeof obj]: typeof obj[K]
  }
  for (const key of keys) {
    ret[key] = obj[key]
  }
  return ret
}

export function omit<
  T extends Record<string, unknown>,
  K extends [...(keyof T)[]]
>(
  obj: T,
  ...keys: K
): {
  [K2 in Exclude<keyof T, K[number]>]: T[K2]
} {
  const ret = {} as {
    [K in keyof typeof obj]: typeof obj[K]
  }
  let key: keyof typeof obj
  for (key in obj) {
    if (!keys.includes(key)) {
      ret[key] = obj[key]
    }
  }
  return ret
}

export function sumBy<T = Record<string, unknown>>(
  list: T[],
  iterator: (item: T) => number
) {
  return list.reduce((acc, item) => {
    return acc + iterator(item)
  }, 0)
}

export function groupBy<T = Record<string, unknown>>(
  list: T[],
  iterator: (item: T) => string
) {
  return list.reduce((acc, item) => {
    const key = iterator(item)
    if (acc[key]) {
      acc[key].push(item)
    } else {
      acc[key] = [item]
    }
    return acc
  }, {} as { [k: string]: T[] })
}
