import './index.scss'

type Props = {
  gap?: number | string
  children: React.ReactNode
}

type ColumnProps = {
  children?: React.ReactNode
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export function Columns({ gap = 16, children }: Props) {
  return (
    <div className="columns" style={{ gap }}>
      {children}
    </div>
  )
}

export function Column({ children, ...rest }: ColumnProps) {
  return (
    <div {...rest} className="columns--column">
      {children}
    </div>
  )
}
