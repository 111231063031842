import { Link as RouteLink } from 'react-router-dom'
import cn from 'classnames'
import { Link } from '@chakra-ui/react'
import { useQueryParams } from '@/hooks/use-query-params'
import './index.scss'

type Props = {
  label: string
  field: string
}

export function SortDirectionLink({ label, field }: Props) {
  const [queryParams] = useQueryParams()
  const current = queryParams.sort || ''
  const [currentField, currentDirection] = current.split(':')
  const isCurrent = currentField === field
  const newDirection = currentDirection === 'ASC' && isCurrent ? 'DESC' : 'ASC'
  const search = Object.entries({
    ...queryParams,
    sort: `${field}:${newDirection}`,
  })
    .map(([k, v]) => `${k}=${v}`)
    .join('&')

  return (
    <Link
      as={RouteLink}
      to={`?${search}`}
      className={cn('sort-direction-link', { [currentDirection]: isCurrent })}
    >
      {label}
    </Link>
  )
}
