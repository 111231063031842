import { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { forwardRef, Select, SelectProps } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'

type Props = {
  projectId: string
  prompt?: string
  value: string | null
  onChange: (value: string | null) => void
} & Omit<SelectProps, 'value' | 'onChange'>

export const projectControlsDocument = graphql(`
  query ProjectControlSelect($projectId: ID!) {
    project(id: $projectId) {
      id
      controls {
        id
        publicRef
      }
    }
  }
`)

export const ProjectControlSelect = forwardRef(
  ({ projectId, prompt, value, onChange, ...rest }: Props, ref) => {
    const { data, error } = useQuery(projectControlsDocument, {
      variables: { projectId },
    })
    const projectControls = data?.project.controls || []

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        onChange?.(e.target.value || null)
      },
      [onChange]
    )

    if (error) throw error

    return (
      <Select ref={ref} value={value ?? ''} onChange={handleChange} {...rest}>
        {prompt && <option value="">{prompt}</option>}
        {projectControls.map((control) => (
          <option key={control.id} value={control.id}>
            {control.publicRef}
          </option>
        ))}
      </Select>
    )
  }
)
