import { forwardRef, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { FileInputButton } from '../file-input-button'

type Value = string | null

export type FileUploadInputProps = {
  required?: boolean
  value?: Value
  onChange?: (value: Value) => void
  onFilename?: (filename: string) => void
}

const signedUploadUrlDocument = graphql(`
  query SignedUploadUrl($input: FileUploadInput!) {
    signedUploadUrl(input: $input)
  }
`)

export const FileUploadInput = forwardRef<
  HTMLInputElement,
  FileUploadInputProps
>(({ value, onChange, onFilename }, ref) => {
  const [isUploading, setIsUploading] = useState(false)
  const client = useApolloClient()

  const handleFiles = async (files: FileList) => {
    const file = files[0]
    if (!file) return
    setIsUploading(true)
    onFilename?.(file.name)

    const res = await client.query({
      query: signedUploadUrlDocument,
      variables: { input: { contentType: file.type, fileName: file.name } },
    })
    const signedUrl = res.data.signedUploadUrl
    await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: { 'Content-Type': file.type },
    })
    setIsUploading(false)
    if (onChange) {
      const { origin, pathname } = new URL(signedUrl)
      onChange(`${origin}${pathname}`)
    }
  }

  const filename = value ? value.split('/').slice(-1)[0] : ''

  return (
    <InputGroup>
      <Input value={filename} isReadOnly pr="96px" />
      <InputRightElement width="88px">
        <FileInputButton
          size="sm"
          onFiles={handleFiles}
          isLoading={isUploading}
          loadingText="Laster opp..."
        >
          Velg fil...
        </FileInputButton>
      </InputRightElement>
    </InputGroup>
  )
})
