import { Table, TableContainer, TableProps } from '@chakra-ui/react'
export { Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import './index.scss'

function CustomTable({ variant = 'striped', ...rest }: TableProps) {
  return (
    <TableContainer>
      <Table className="table" variant={variant} {...rest} />
    </TableContainer>
  )
}

export { CustomTable as Table }
