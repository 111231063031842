import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { useMutation, useQuery } from '@apollo/client'
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { Drawer } from '@/components/drawer'
import { LoadingSection } from '@/components/loading-section'
import { SpecialistForm, FormValues } from './components/specialist-form'

export const editSpecialistFragment = graphql(`
  fragment EditSpecialistItem on Specialist {
    id
    name
    email
    mobile
    companyName
    services
    comment
    address {
      streetAddress
      postalCode
      postalArea
    }
    categories {
      id
      name
    }
  }
`)

const editSpecialistDocument = graphql(`
  query EditSpecialist($id: ID!) {
    specialist(id: $id) {
      ...EditSpecialistItem
    }
  }
`)

const updateSpecialistDocument = graphql(`
  mutation UpdateSpecialist($id: ID!, $input: SpecialistInput!) {
    updateSpecialist(id: $id, input: $input) {
      message
      specialist {
        ...EditSpecialistItem
      }
    }
  }
`)

export function EditSpecialistRoute() {
  const params = z.object({ specialistId: z.string() }).parse(useParams())
  const navigate = useNavigate()
  const toast = useToast()
  const onCancel = useCallback(
    () => navigate(`../${params.specialistId}`),
    [navigate, params]
  )

  const { data, loading, error } = useQuery(editSpecialistDocument, {
    variables: { id: params.specialistId },
  })
  const [updateSpecialist, { loading: isSubmitting }] = useMutation(
    updateSpecialistDocument,
    {
      onCompleted(data) {
        toast({
          status: 'success',
          title: data?.updateSpecialist.message,
          isClosable: true,
        })
        onCancel()
      },
      onError(error) {
        toast({
          status: 'error',
          title: error.message,
          isClosable: true,
        })
      },
    }
  )

  const onSubmit = useCallback(
    async (input: FormValues) => {
      await updateSpecialist({
        variables: { id: params.specialistId, input },
      })
    },
    [updateSpecialist, params]
  )

  if (error) throw error

  const specialist = data?.specialist

  return (
    <Drawer
      title="Endre spesialist"
      size="lg"
      onClose={() => navigate('..')}
      footer={
        <ButtonGroup>
          <Button onClick={onCancel}>Avbryt</Button>
          <Button
            form="specialist-form"
            type="submit"
            colorScheme="blue"
            isLoading={isSubmitting}
          >
            Lagre spesialist
          </Button>
        </ButtonGroup>
      }
    >
      {loading && <LoadingSection />}
      {!!specialist && (
        <SpecialistForm
          onSubmit={onSubmit}
          defaultValues={{
            name: specialist.name,
            email: specialist.email,
            mobile: specialist.mobile,
            companyName: specialist.companyName ?? undefined,
            services: specialist.services ?? undefined,
            comment: specialist.comment ?? undefined,
            address: {
              streetAddress: specialist.address.streetAddress,
              postalCode: specialist.address.postalCode,
              postalArea: specialist.address.postalArea,
            },
            categories: specialist.categories.map((category) => ({
              id: category.id,
            })),
          }}
        />
      )}
    </Drawer>
  )
}
