import { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { Select, SelectProps, forwardRef } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'

type Props = {
  prompt?: string
  value: string | null
  onChange: (value: string | null) => void
} & Omit<SelectProps, 'value' | 'onChange'>

const categoriesDocument = graphql(`
  query CategorySelect {
    categories(input: { parentId: null }) {
      results {
        id
        name
        children {
          id
          name
        }
      }
    }
  }
`)

export const CategorySelect = forwardRef(
  ({ prompt, value, onChange, ...rest }: Props, ref) => {
    const { data, error } = useQuery(categoriesDocument)
    const categories = data?.categories.results || []

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        onChange(e.target.value || null)
      },
      [onChange]
    )

    if (error) throw error

    return (
      <Select ref={ref} value={value ?? ''} onChange={handleChange} {...rest}>
        {prompt && <option value="">{prompt}</option>}
        {categories.map((parent) => (
          <optgroup key={parent.id} label={parent.name}>
            {parent.children.map((child) => (
              <option key={child.id} value={child.id}>
                {child.name}
              </option>
            ))}
          </optgroup>
        ))}
      </Select>
    )
  }
)
