import { Routes, Route } from 'react-router-dom'
import { SpecialistRoute } from './specialist'
import { SpecialistsRoute } from './specialists'
import { NewSpecialistRoute } from './new'
import { EditSpecialistRoute } from './edit'

export function SpecialistsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SpecialistsRoute />}>
        <Route path="new" element={<NewSpecialistRoute />} />
        <Route path=":specialistId" element={<SpecialistRoute />} />
        <Route path=":specialistId/edit" element={<EditSpecialistRoute />} />
      </Route>
    </Routes>
  )
}
