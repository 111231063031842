import { Routes, Route, Navigate } from 'react-router-dom'
import { useViewer } from '@/components/viewer-context-provider'
import { NotFoundRoute } from './not-found'
import { LoginRoute } from './login'
import { DashboardRoute } from './dashboard'
import { UsersRoutes } from './users'
import { ClientsRoutes } from './clients'
import { SuppliersRoutes } from './suppliers'
import { CategoriesRoutes } from './categories'
import { SpecialistsRoutes } from './specialists'
import { DocumentsRoutes } from './documents'
import { InvoicesRoutes } from './invoices'
import { OffersRoutes } from './offers'

function Authenticated(Component: () => JSX.Element) {
  const viewer = useViewer()
  if (!viewer.user) {
    return <Navigate to="/login" replace />
  }
  return <Component />
}

export function RootRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Navigate to="/login/no" replace />} />
      <Route path="/login/:instanceId" element={<LoginRoute />} />
      <Route path="/users/*" element={Authenticated(UsersRoutes)} />
      <Route path="/clients/*" element={Authenticated(ClientsRoutes)} />
      <Route path="/suppliers/*" element={Authenticated(SuppliersRoutes)} />
      <Route path="/categories/*" element={Authenticated(CategoriesRoutes)} />
      <Route path="/specialists/*" element={Authenticated(SpecialistsRoutes)} />
      <Route path="/documents/*" element={Authenticated(DocumentsRoutes)} />
      <Route path="/invoices/*" element={Authenticated(InvoicesRoutes)} />
      <Route path="/offers/*" element={Authenticated(OffersRoutes)} />
      <Route path="/" element={Authenticated(DashboardRoute)} />
      <Route path="*" element={<NotFoundRoute />} />
    </Routes>
  )
}
