import { UseFormRegister } from 'react-hook-form'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { Input } from '../input'
import { Row, Rows } from '../rows'
import { Column, Columns } from '../columns'

type Props = {
  register: UseFormRegister<any>
  prefix?: string
  label?: string
  isRequired?: boolean
}

export function AddressFormFields({
  register,
  label = 'Adresse',
  prefix = 'address',
  isRequired = false,
}: Props) {
  return (
    <div className="address-form-fields">
      <Rows>
        <Row>
          <FormControl isRequired={isRequired}>
            <FormLabel>{label}</FormLabel>
            <Input
              placeholder="Gateadresse"
              {...register(`${prefix}.streetAddress`, { required: isRequired })}
            />
          </FormControl>
        </Row>
        <Row>
          <Columns>
            <Column style={{ flexGrow: 0.5 }}>
              <FormControl isRequired={isRequired}>
                <Input
                  placeholder="Postkode"
                  {...register(`${prefix}.postalCode`, {
                    required: isRequired,
                  })}
                />
              </FormControl>
            </Column>
            <Column>
              <FormControl isRequired={isRequired}>
                <Input
                  placeholder="Poststed"
                  {...register(`${prefix}.postalArea`, {
                    required: isRequired,
                  })}
                />
              </FormControl>
            </Column>
          </Columns>
        </Row>
      </Rows>
    </div>
  )
}
