import { Link as RouteLink, Outlet } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Button, Link } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { usePagination } from '@/hooks/use-pagination'
import { formatDate } from '@/utils/format'
import { FullLayout } from '@/components/full-layout'
import { ContainerBox } from '@/components/container-box'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/components/table'
import { Rows, Row } from '@/components/rows'
import { Heading } from '@/components/heading'
import { LoadingSection } from '@/components/loading-section'

export const offersDocument = graphql(`
  query Offers($input: ConnectionInputArgs!) {
    offers(input: $input) {
      totalCount
      results {
        id
        date
        supplier {
          id
          name
        }
        category {
          id
          name
        }
        costSize {
          id
          name
        }
      }
    }
  }
`)

export function OffersRoute() {
  const { Pagination, variables } = usePagination()
  const { data, loading, error } = useQuery(offersDocument, {
    variables: { input: variables },
  })
  const offers = data?.offers.results || []
  const totalCount = data?.offers.totalCount || 0

  if (error) throw error

  return (
    <FullLayout>
      <ContainerBox>
        <Rows>
          <Row>
            <Heading
              title="Benchmarkbase"
              actions={
                <Button as={RouteLink} to="new" size="sm" colorScheme="blue">
                  Ny benchmark +
                </Button>
              }
            />
          </Row>
          <Row>
            <Table>
              <Thead>
                <Tr>
                  <Th>Leverandør</Th>
                  <Th>Kategori</Th>
                  <Th>Kategoristørrelse</Th>
                  <Th>Dato</Th>
                </Tr>
              </Thead>
              <Tbody>
                {offers.map((offer) => (
                  <Tr key={offer.id}>
                    <Td>
                      <Link as={RouteLink} to={offer.id}>
                        {offer.supplier.name}
                      </Link>
                    </Td>
                    <Td>{offer.category.name}</Td>
                    <Td>{offer.costSize.name}</Td>
                    <Td>{formatDate(offer.date)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {loading && <LoadingSection />}
          </Row>
          {totalCount > variables.limit && (
            <Row>
              <Pagination total={totalCount} />
            </Row>
          )}
        </Rows>
      </ContainerBox>

      <Outlet />
    </FullLayout>
  )
}
