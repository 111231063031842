import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { z } from 'zod'
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { Drawer } from '@/components/drawer'
import {
  ProjectControlForm,
  FormValues,
} from './components/project-control-form'

const createProjectControlDocument = graphql(`
  mutation CreateProjectControl($input: ProjectControlInput!) {
    createProjectControl(input: $input) {
      message
      projectControl {
        id
      }
    }
  }
`)

export function NewProjectControlRoute() {
  const params = z
    .object({ clientId: z.string(), projectId: z.string() })
    .parse(useParams())
  const navigate = useNavigate()
  const toast = useToast()
  const onClose = useCallback(() => navigate('..'), [navigate])

  const [createProject, { loading: isSubmitting }] = useMutation(
    createProjectControlDocument,
    {
      refetchQueries: ['ClientProjects'],
      onCompleted(data) {
        toast({
          status: 'success',
          title: data.createProjectControl.message,
          isClosable: true,
        })
        onClose()
      },
      onError(error) {
        toast({
          status: 'error',
          title: error.message,
          isClosable: true,
        })
      },
    }
  )

  const onSubmit = async (input: FormValues) => {
    await createProject({
      variables: { input: { projectId: params.projectId, ...input } },
    })
  }

  return (
    <Drawer
      title="Ny etterkontroll"
      onClose={onClose}
      footer={
        <ButtonGroup>
          <Button onClick={onClose}>Avbryt</Button>
          <Button
            form="project-control-form"
            type="submit"
            colorScheme="blue"
            isLoading={isSubmitting}
          >
            Opprett etterkontroll
          </Button>
        </ButtonGroup>
      }
    >
      <ProjectControlForm onSubmit={onSubmit} />
    </Drawer>
  )
}
