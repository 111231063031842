import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { config } from '@/config'

type Options = {
  fetch?: WindowOrWorkerGlobalScope['fetch']
}

function getSessionToken() {
  const match = document.cookie.match(
    new RegExp(`(^| )${config.sessionToken}=([^;]+)`)
  )
  return match ? match[2] : null
}

let tokenExpiry: number | null

export function createApolloClient(inputOptions: Options) {
  const options = { fetch, ...inputOptions }

  const authLink = setContext(async () => {
    if (!tokenExpiry) {
      const token = getSessionToken()
      if (token) {
        tokenExpiry = JSON.parse(window.atob(token.split('.')[1])).exp
      }
    }
    if (tokenExpiry) {
      const now = new Date().getTime() / 1000
      if (now >= tokenExpiry) {
        tokenExpiry = null
        await options
          .fetch(config.authRefreshUrl, {
            method: 'POST',
            credentials: 'include',
          })
          .catch(() => null)
      }
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        console.log(`[GraphQL error]: ${error}`)
        if (error.extensions.code === 'UNAUTHENTICATED') {
          window.location.replace('/login')
          return
        }
      }
    }
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

  const httpLink = new HttpLink({
    uri: config.graphqlUrl,
    credentials: 'include',
    ...options,
  })

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        UsersConnection: { merge: false },
        Viewer: { merge: true },
        Address: { merge: true },
        InvoiceRecipient: { merge: true },
        Client: { fields: { contacts: { merge: false } } },
        Supplier: { fields: { contacts: { merge: false } } },
      },
    }),
    link: from([errorLink, authLink, httpLink]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  })
}
