import { ReactNode, Component, ErrorInfo } from 'react'
import { FallbackError } from './fallback-error'

interface Props {
  children: ReactNode
}

interface State {
  error: Error | null
}

export class ErrorBoundary extends Component<Props, State> {
  state: State = {
    error: null,
  }

  static getDerivedStateFromError(error: Error): State {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.error) {
      return <FallbackError error={this.state.error} />
    }

    return this.props.children
  }
}
