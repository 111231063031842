import './index.scss'

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export function ContainerBox(props: Props) {
  return <div {...props} className="container-box" />
}
