import { Controller, useForm } from 'react-hook-form'
import { FormLabel, FormControl } from '@chakra-ui/react'
import { Row, Rows } from '@/components/rows'
import { ClientSelect } from '@/components/client-select'
import { ProjectSelect } from '@/components/project-select'
import { ProjectControlSelect } from '@/components/project-control-select'
import { Input } from '@/components/input'

type Props = {
  onSubmit: (input: FormValues) => Promise<void> | void
  defaultValues?: Partial<FormValues>
}

export type FormValues = {
  clientId: string
  projectId: string | null
  projectControlId: string | null
  type: string
  invoiceVariant: string
  invoiceYear: number
  invoiceNumber: number
  issueDate: string
  dueDate: string
  description: string
  recipient: {
    name: string
    contactPerson: string
    email: string
    address: {
      streetAddress: string
      postalCode: string
      postalArea: string
    }
  }
  items: {
    id?: string
    productId: string
    description: string
    unitPrice: number
    quantity: number
    vatRate: number
  }[]
}

export function CreateInvoiceForm({ defaultValues, onSubmit }: Props) {
  const { register, control, handleSubmit, watch } = useForm<FormValues>({
    defaultValues,
  })

  const [clientId, projectId] = watch(['clientId', 'projectId'])

  return (
    <form id="invoice-form" onSubmit={handleSubmit(onSubmit)}>
      <Rows>
        <Row>
          <FormControl isRequired>
            <FormLabel>Dato</FormLabel>
            <Input type="date" {...register('issueDate', { required: true })} />
          </FormControl>
        </Row>
        <Row>
          <FormControl isRequired>
            <FormLabel>Forfall</FormLabel>
            <Input type="date" {...register('dueDate', { required: true })} />
          </FormControl>
        </Row>
        <Row>
          <FormControl isRequired>
            <FormLabel>Kunde</FormLabel>
            <Controller
              control={control}
              name="clientId"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <ClientSelect
                  prompt="Velg kunde"
                  value={value}
                  onChange={(v) => onChange(v ?? '')}
                />
              )}
            />
          </FormControl>
        </Row>
        {!!clientId && (
          <Row>
            <FormControl>
              <FormLabel>Prosjekt</FormLabel>
              <Controller
                control={control}
                name="projectId"
                render={({ field: { value, onChange } }) => (
                  <ProjectSelect
                    clientId={clientId}
                    prompt="Velg prosjekt"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </FormControl>
          </Row>
        )}
        {!!projectId && (
          <Row>
            <FormControl>
              <FormLabel>Etterkontroll</FormLabel>
              <Controller
                control={control}
                name="projectControlId"
                render={({ field: { value, onChange } }) => (
                  <ProjectControlSelect
                    projectId={projectId}
                    prompt="Velg etterkontroll"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </FormControl>
          </Row>
        )}
      </Rows>
    </form>
  )
}
