import './index.scss'

type Props = {
  gap?: number | string
  children: React.ReactNode
}

type RowProps = {
  children: React.ReactNode
}

export function Rows({ gap = 16, children }: Props) {
  return (
    <div className="rows" style={{ gap }}>
      {children}
    </div>
  )
}

export function Row({ children }: RowProps) {
  return <div className="rows--row">{children}</div>
}

export function Divider() {
  return <div className="rows--divider" />
}
