const apiUrl = import.meta.env.VITE_API_URL
const sentryDsn = import.meta.env.VITE_SENTRY_DSN

export const config = {
  graphqlUrl: `${apiUrl}/graphql`,
  authRefreshUrl: `${apiUrl}/auth/refresh`,
  authSessionUrl: `${apiUrl}/auth/session`,
  pdfPreviewUrl: `${apiUrl}/pdf`,
  exportUrl: `${apiUrl}/export`,
  sessionToken: 'cp_session_token',
  sentry: {
    dsn: sentryDsn,
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  },
}
