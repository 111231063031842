import { useCallback, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { useMutation } from '@apollo/client'
import { Tab, TabList, Tabs, useToast } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { useViewer } from '@/components/viewer-context-provider'
import { AuthLayout } from '@/components/auth-layout'
import { Row, Rows } from '@/components/rows'
import { SignInForm, FormValues } from './components/sign-in-form'

const requestOtpDocument = graphql(`
  mutation RequestOtp($input: OtpInput!) {
    requestOtp(input: $input) {
      message
    }
  }
`)

const signInDocument = graphql(`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      message
      viewer {
        id
        user {
          id
        }
      }
    }
  }
`)

export function LoginRoute() {
  const viewer = useViewer()
  const toast = useToast()
  const params = z.object({ instanceId: z.string() }).parse(useParams())
  const [email, setEmail] = useState<string>()
  const [requestOtp] = useMutation(requestOtpDocument, {
    onCompleted: (data, options) => {
      toast({
        title: data.requestOtp.message,
        status: 'success',
      })
      setEmail(options?.variables?.input.email)
    },
    onError: (data) =>
      toast({
        title: data.message,
        status: 'error',
      }),
  })
  const [signIn] = useMutation(signInDocument, {
    refetchQueries: ['ViewerContextProvider'],
    onError: () =>
      toast({
        title: 'Wrong username and/or password',
        status: 'error',
      }),
  })

  const handleSubmit = useCallback(
    async (input: FormValues) => {
      if (input.email && input.otp) {
        await signIn({
          variables: { input: { ...input, instanceId: params.instanceId } },
        })
      } else if (input.email) {
        await requestOtp({
          variables: { input: { ...input, instanceId: params.instanceId } },
        })
      }
    },
    [signIn, requestOtp, params.instanceId]
  )

  if (viewer.user) {
    return <Navigate to="/" replace />
  }

  return (
    <AuthLayout>
      <h1>Costpartner Basen</h1>
      <Rows>
        <Row>
          <Tabs>
            <TabList>
              <Tab as={Link} to="../login/no" isDisabled={!!email}>
                Norge
              </Tab>
              <Tab as={Link} to="../login/se" isDisabled={!!email}>
                Sverige
              </Tab>
            </TabList>
          </Tabs>
        </Row>
        <Row>
          <SignInForm key={email} email={email} onSubmit={handleSubmit} />
        </Row>
      </Rows>
    </AuthLayout>
  )
}
