import { useEffect, useRef } from 'react'
import { ApolloError } from '@apollo/client'
import './index.scss'

type Props = {
  error?: ApolloError
}

export function SubmitError({ error }: Props) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref.current?.scrollIntoView()
  }, [ref.current])

  if (!error) return null

  const messages: string[] = []
  for (const graphqlError of error.graphQLErrors) {
    if (graphqlError.extensions.errors) {
      if (Array.isArray(graphqlError.extensions.errors))
        for (const { message } of graphqlError.extensions.errors) {
          messages.push(message)
        }
    }
  }
  if (messages.length === 0) messages.push(error.message)

  return (
    <div ref={ref} className="submit-error">
      <span className="submit-error--heading">
        En eller flere feil oppstod under innsending:
      </span>
      <ul className="submit-error--errors">
        {messages.map((msg, i) => (
          <li key={i}>{msg}</li>
        ))}
      </ul>
    </div>
  )
}
