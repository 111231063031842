import { useQuery } from '@apollo/client'
import { Select } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'

type Props = {
  values: string[]
  onChange: (v: string[]) => void
}

const categoriesDocument = graphql(`
  query CategoriesSelect {
    categories(input: { parentId: null }) {
      results {
        id
        name
        children {
          id
          name
        }
      }
    }
  }
`)

export function CategoriesSelect({ values, onChange }: Props) {
  const { data } = useQuery(categoriesDocument)
  const categories = data?.categories.results || []

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const values = [...e.target.options]
      .filter((o) => o.selected)
      .map((o) => o.value)
    onChange(values)
  }

  return (
    <Select
      multiple
      style={{ height: 150 }}
      value={values}
      onChange={handleChange}
    >
      {categories.map((parent) => (
        <optgroup key={parent.id} label={parent.name}>
          {parent.children.map((child) => (
            <option key={child.id} value={child.id}>
              {child.name}
            </option>
          ))}
        </optgroup>
      ))}
    </Select>
  )
}
