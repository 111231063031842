import { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { forwardRef, Select, SelectProps } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'

type Props = {
  clientId: string
  prompt?: string
  value: string | null
  onChange: (value: string | null) => void
} & Omit<SelectProps, 'value' | 'onChange'>

export const projectsDocument = graphql(`
  query ProjectSelect($input: ProjectsConnectionInputArgs!) {
    projects(input: $input) {
      results {
        id
        publicRef
        category {
          id
          name
        }
      }
    }
  }
`)

export const ProjectSelect = forwardRef(
  ({ clientId, prompt, value, onChange, ...rest }: Props, ref) => {
    const { data, error } = useQuery(projectsDocument, {
      variables: { input: { clientId } },
    })
    const projects = data?.projects.results || []

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        onChange?.(e.target.value || null)
      },
      [onChange]
    )

    if (error) throw error

    return (
      <Select ref={ref} value={value ?? ''} onChange={handleChange} {...rest}>
        {prompt && <option value="">{prompt}</option>}
        {projects.map((project) => (
          <option key={project.id} value={project.id}>
            {project.publicRef} {project.category.name}
          </option>
        ))}
      </Select>
    )
  }
)
