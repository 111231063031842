import { Routes, Route } from 'react-router-dom'
import { OffersRoute } from './offers'
import { NewOfferRoute } from './new'
import { OfferRoute } from './offer'

export function OffersRoutes() {
  return (
    <Routes>
      <Route path="/" element={<OffersRoute />}>
        <Route path="new" element={<NewOfferRoute />} />
        <Route path=":offerId" element={<OfferRoute />} />
      </Route>
    </Routes>
  )
}
