import { Routes, Route } from 'react-router-dom'
import { ClientRoute } from './client'
import { ClientsRoute } from './clients'
import { EditClientRoute } from './edit'
import { NewClientRoute } from './new'
import { ProjectsRoute } from './projects'
import { NewProjectRoute } from './new-project'
import { EditProjectRoute } from './edit-project'
import { NewProjectControlRoute } from './new-project-control'
import { EditProjectControlRoute } from './edit-project-control'

export function ClientsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ClientsRoute />}>
        <Route path="new" element={<NewClientRoute />} />
        <Route path=":clientId/edit" element={<EditClientRoute />} />
        <Route path=":clientId" element={<ClientRoute />} />
      </Route>
      <Route path=":clientId/projects" element={<ProjectsRoute />}>
        <Route path="new" element={<NewProjectRoute />} />
        <Route path=":projectId/edit" element={<EditProjectRoute />} />
        <Route
          path=":projectId/controls/new"
          element={<NewProjectControlRoute />}
        />
        <Route
          path=":projectId/controls/:projectControlId/edit"
          element={<EditProjectControlRoute />}
        />
      </Route>
    </Routes>
  )
}
