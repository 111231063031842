import { Link as RouteLink } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Link } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { formatDate, formatNumber } from '@/utils/format'
import { ContainerBox } from '@/components/container-box'
import { FullLayout } from '@/components/full-layout'
import { Heading } from '@/components/heading'
import { Row, Rows } from '@/components/rows'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/components/table'
import { DownloadExportButton } from '@/components/download-export-button'
import { LoadingSection } from '@/components/loading-section'
import { useViewer } from '@/components/viewer-context-provider'

const projectsDocument = graphql(`
  query DashboardProjects($input: ProjectsConnectionInputArgs!) {
    projects(input: $input) {
      results {
        id
        publicRef
        date
        estimatedBenchmark
        reportBenchmark
        reportSavings
        client {
          id
          name
          status
          user {
            id
            name
          }
        }
        category {
          id
          name
        }
      }
    }
  }
`)

export function DashboardRoute() {
  const { isAdmin } = useViewer()
  const { data, loading } = useQuery(projectsDocument, {
    variables: { input: { limit: 10 } },
  })

  const projects = data?.projects.results ?? []

  return (
    <FullLayout>
      <ContainerBox>
        <Rows>
          <Row>
            <Heading
              title="Siste kundeavtaler"
              actions={
                <>
                  <DownloadExportButton
                    label="Prosjekter (Excel)"
                    file="projects.xlsx"
                  />
                  {isAdmin && (
                    <DownloadExportButton
                      label="Inntektsestimat (Excel)"
                      file="income-estimate.xlsx"
                    />
                  )}
                </>
              }
            />
          </Row>
          <Row>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Kunde</Th>
                  <Th>Status</Th>
                  <Th>Dato</Th>
                  <Th>Kategori</Th>
                  <Th>Rådgiver</Th>
                  <Th>
                    <abbr title="BM estimat (kr pr. år)">Estimat</abbr>
                  </Th>
                  <Th>
                    <abbr title="BM i kr (actual) fra rapport">Benchmark</abbr>
                  </Th>
                  <Th>
                    <abbr title="Besparelse i kr fra rapport">Besparelse</abbr>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {projects.map((project) => (
                  <Tr key={project.id}>
                    <Td>
                      <Link
                        as={RouteLink}
                        to={`/clients/${project.client.id}/projects`}
                      >
                        {project.publicRef}
                      </Link>
                    </Td>
                    <Td>{project.client.name}</Td>
                    <Td>{project.client.status}</Td>
                    <Td>{project.date ? formatDate(project.date) : '-'}</Td>
                    <Td>{project.category.name}</Td>
                    <Td>{project.client.user?.name.split(' ')[0] || '-'}</Td>
                    <Td>
                      {project.estimatedBenchmark
                        ? formatNumber(project.estimatedBenchmark, 0)
                        : '-'}
                    </Td>
                    <Td>
                      {project.reportBenchmark
                        ? formatNumber(project.reportBenchmark, 0)
                        : '-'}
                    </Td>
                    <Td>
                      {project.reportSavings
                        ? formatNumber(project.reportSavings, 0)
                        : '-'}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {loading && <LoadingSection />}
          </Row>
        </Rows>
      </ContainerBox>
    </FullLayout>
  )
}
