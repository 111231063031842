import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { Drawer } from '@/components/drawer'
import { SpecialistForm, FormValues } from './components/specialist-form'

const createSpecialistDocument = graphql(`
  mutation CreateSpecialist($input: SpecialistInput!) {
    createSpecialist(input: $input) {
      message
      specialist {
        id
      }
    }
  }
`)

export function NewSpecialistRoute() {
  const navigate = useNavigate()
  const toast = useToast()
  const onClose = useCallback(() => navigate('..'), [navigate])

  const [createSpecialist, { loading: isSubmitting }] = useMutation(
    createSpecialistDocument,
    {
      refetchQueries: ['Specialists'],
      onCompleted(data) {
        toast({
          status: 'success',
          title: data.createSpecialist.message,
          isClosable: true,
        })
        onClose()
      },
      onError(error) {
        toast({
          status: 'error',
          title: error.message,
          isClosable: true,
        })
      },
    }
  )

  const onSubmit = useCallback(
    async (input: FormValues) => {
      await createSpecialist({ variables: { input } })
    },
    [createSpecialist]
  )

  return (
    <Drawer
      title="Ny spesialist"
      size="lg"
      onClose={onClose}
      footer={
        <ButtonGroup>
          <Button onClick={onClose}>Avbryt</Button>
          <Button
            form="specialist-form"
            type="submit"
            colorScheme="blue"
            isLoading={isSubmitting}
          >
            Opprett spesialist
          </Button>
        </ButtonGroup>
      }
    >
      <SpecialistForm
        onSubmit={onSubmit}
        defaultValues={{
          mobile: '+47',
        }}
      />
    </Drawer>
  )
}
