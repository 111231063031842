import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { useQuery } from '@apollo/client'
import { graphql } from '@/__generated__/gql'
import { formatNumber } from '@/utils/format'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/components/table'
import { Drawer } from '@/components/drawer'
import { LoadingSection } from '@/components/loading-section'

export const offerDocument = graphql(`
  query Offer($id: ID!) {
    offer(id: $id) {
      id
      date
      supplier {
        id
        name
      }
      category {
        id
        name
      }
      costSize {
        id
        name
      }
      items {
        results {
          id
          code
          name
          unit
          unitPrice
        }
      }
    }
  }
`)

export function OfferRoute() {
  const params = z.object({ offerId: z.string() }).parse(useParams())
  const navigate = useNavigate()
  const onClose = useCallback(() => navigate('..'), [navigate])

  const { data, loading, error } = useQuery(offerDocument, {
    variables: { id: params.offerId },
  })
  const items = data?.offer.items.results || []

  if (error) throw error

  return (
    <Drawer title="Benchmark" size="lg" onClose={onClose}>
      <Table>
        <Thead>
          <Tr>
            <Th>Varekode</Th>
            <Th>Varetekst</Th>
            <Th>Enhet</Th>
            <Th>Enhetspris</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map((item) => (
            <Tr key={item.id}>
              <Td>{item.code}</Td>
              <Td>{item.name}</Td>
              <Td>{item.unit}</Td>
              <Td>{formatNumber(item.unitPrice, 2)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {loading && <LoadingSection />}
    </Drawer>
  )
}
