import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

type DefaultValues = { [k: string]: string }

export function useQueryParams(defaultValues?: DefaultValues) {
  const [params, setParams] = useSearchParams()
  const queryParams = Array.from(params.entries()).reduce((acc, item) => {
    return { ...acc, [item[0]]: item[1] }
  }, {})

  const setQueryParams = useCallback(
    (value: { [k: string]: string }) => {
      setParams({ ...queryParams, ...value })
    },
    [queryParams, setParams]
  )

  return [{ ...defaultValues, ...queryParams }, setQueryParams] as [
    { [k: string]: string },
    typeof setQueryParams
  ]
}
