import { useForm } from 'react-hook-form'
import {
  Button,
  FormControl,
  Stack,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  Input,
} from '@chakra-ui/react'
import { MdOutlineMail, MdOutlinePassword } from 'react-icons/md'
import { EMAIL_PATTERN } from '@/lib/consts'

type Props = {
  onSubmit: (input: FormValues) => Promise<void>
  email?: string
}

export type FormValues = {
  email: string
  otp: string
}

export function SignInForm({ onSubmit, email }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ mode: 'onBlur', defaultValues: { email } })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <FormControl isInvalid={!!errors.email}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <MdOutlineMail />
            </InputLeftElement>
            <Input
              autoFocus={!email}
              placeholder="E-post"
              {...register('email', {
                required: true,
                pattern: EMAIL_PATTERN,
              })}
            />
          </InputGroup>
          {!!errors.email && (
            <FormErrorMessage fontSize="xs">E-post er påkrevd</FormErrorMessage>
          )}
        </FormControl>

        {!!email && (
          <FormControl isRequired isInvalid={!!errors.otp}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <MdOutlinePassword />
              </InputLeftElement>
              <Input
                autoFocus={!!email}
                placeholder="Engangspassord"
                {...register('otp', { required: true, minLength: 5 })}
              />
            </InputGroup>
            {!!errors.otp && (
              <FormErrorMessage fontSize="xs">
                Engangspassord er påkrevd
              </FormErrorMessage>
            )}
          </FormControl>
        )}

        <Button type="submit" colorScheme="blue">
          {email ? 'Logg inn' : 'Fortsett'}
        </Button>
      </Stack>
    </form>
  )
}
