import React from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
} from '@chakra-ui/react'
import './index.scss'

type Props = {
  title?: string
  children: React.ReactNode
  footer?: React.ReactNode
} & Omit<DrawerProps, 'isOpen'>

function CustomDrawer({ title, children, footer, ...rest }: Props) {
  return (
    <Drawer
      isOpen
      closeOnOverlayClick={false}
      closeOnEsc
      placement="right"
      size="sm"
      {...rest}
    >
      <DrawerOverlay />
      <DrawerContent transitionDuration="50ms">
        <DrawerCloseButton />
        {!!title && <DrawerHeader>{title}</DrawerHeader>}
        <DrawerBody>{children}</DrawerBody>
        {!!footer && <DrawerFooter>{footer}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  )
}

export { CustomDrawer as Drawer }
