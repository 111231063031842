import { useCallback } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { useQuery } from '@apollo/client'
import { Button, ButtonGroup } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { formatDateTime, formatPhoneNumber } from '@/utils/format'
import { Drawer } from '@/components/drawer'
import { Row, Rows } from '@/components/rows'
import { Column, Columns } from '@/components/columns'
import { LabelledContent } from '@/components/labelled-content'
import { ActionsBar } from '@/components/actions-bar'
import { LoadingSection } from '@/components/loading-section'

const supplierDocument = graphql(`
  query Supplier($id: ID!) {
    supplier(id: $id) {
      id
      publicRef
      rating
      name
      organizationNumber
      email
      phone
      comment
      employeesCount
      employeesYear
      revenuesYear
      revenuesAmount
      businessSector
      createdAt
      updatedAt
      canEdit
      user {
        id
        name
      }
      address {
        streetAddress
        postalCode
        postalArea
      }
      postAddress {
        streetAddress
        postalCode
        postalArea
      }
      contacts {
        name
        position
        email
        phone
      }
      categories {
        id
        name
      }
    }
  }
`)

export function SupplierRoute() {
  const params = z.object({ supplierId: z.string() }).parse(useParams())
  const navigate = useNavigate()
  const onClose = useCallback(() => navigate('..'), [navigate])

  const { data, loading, error } = useQuery(supplierDocument, {
    variables: { id: params.supplierId },
  })

  if (error) throw error

  const supplier = data?.supplier

  return (
    <Drawer size="lg" title={supplier?.name || ''} onClose={onClose}>
      {loading && <LoadingSection />}
      {!!supplier && (
        <Rows>
          {supplier.canEdit && (
            <Row>
              <ActionsBar>
                <ButtonGroup size="sm">
                  <Button as={Link} to="./edit">
                    Endre
                  </Button>
                </ButtonGroup>
              </ActionsBar>
            </Row>
          )}
          <Row>
            <Columns gap={32}>
              <Column>
                <Rows>
                  <Row>
                    <LabelledContent label="Kundenummer">
                      {supplier.publicRef}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Rating">
                      {supplier.rating}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Org.nr.">
                      {supplier.organizationNumber}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Telefon">
                      {supplier.phone || '-'}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Email">
                      {supplier.email || '-'}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Adresse">
                      {supplier.address ? (
                        <>
                          {supplier.address.streetAddress},{' '}
                          {supplier.address.postalCode}{' '}
                          {supplier.address.postalArea}
                        </>
                      ) : (
                        '-'
                      )}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Postadresse">
                      {supplier.postAddress ? (
                        <>
                          {supplier.postAddress.streetAddress},{' '}
                          {supplier.postAddress.postalCode}{' '}
                          {supplier.postAddress.postalArea}
                        </>
                      ) : (
                        '-'
                      )}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Kontaktpersoner">
                      <Rows>
                        {supplier.contacts.map((contact, i) => (
                          <Row key={i}>
                            <div>
                              {contact.name}
                              {contact.position ? ` (${contact.position})` : ''}
                            </div>
                            {contact.email && <div>{contact.email}</div>}
                            {contact.phone && (
                              <div>{formatPhoneNumber(contact.phone)}</div>
                            )}
                          </Row>
                        ))}
                      </Rows>
                    </LabelledContent>
                  </Row>
                </Rows>
              </Column>

              <Column>
                <Rows>
                  <Row>
                    <LabelledContent label="Rådgiver">
                      {supplier.user?.name || '-'}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Omsetning">
                      {supplier.revenuesAmount ? (
                        <>
                          {supplier.revenuesAmount}
                          {supplier.revenuesYear && (
                            <>({supplier.revenuesYear})</>
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Ansatte">
                      {supplier.employeesCount ? (
                        <>
                          {supplier.employeesCount}
                          {supplier.employeesYear && (
                            <>({supplier.employeesYear})</>
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Bransje">
                      {supplier.businessSector || '-'}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Registreringsdato">
                      {formatDateTime(supplier.createdAt)}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Sist oppdatert">
                      {formatDateTime(supplier.updatedAt)}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Kommentar">
                      {supplier.comment || '-'}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Kategorier">
                      {supplier.categories.map((category) => (
                        <div key={category.id}>{category.name}</div>
                      ))}
                    </LabelledContent>
                  </Row>
                </Rows>
              </Column>
            </Columns>
          </Row>
        </Rows>
      )}
    </Drawer>
  )
}
