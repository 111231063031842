import { Outlet } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { graphql } from '@/__generated__/gql'
import { FullLayout } from '@/components/full-layout'
import { ContainerBox } from '@/components/container-box'
import { Ul } from '@/components/ul'
import { Rows, Row } from '@/components/rows'
import { LoadingSection } from '@/components/loading-section'
import { Heading } from '@/components/heading'

const categoriesDocument = graphql(`
  query Categories {
    categories(input: { parentId: null }) {
      results {
        id
        name
        children {
          id
          name
        }
      }
    }
  }
`)

export function CategoriesRoute() {
  const { data, loading, error } = useQuery(categoriesDocument)
  const categories = data?.categories.results || []

  if (error) throw error

  return (
    <FullLayout>
      <ContainerBox>
        <Rows>
          <Row>
            <Heading title="Kategorier" />
          </Row>
          <Row>
            <Ul>
              {categories.map((parent) => (
                <li key={parent.id}>
                  {parent.name}
                  <Ul>
                    {parent.children.map((child) => (
                      <li key={child.id}>{child.name}</li>
                    ))}
                  </Ul>
                </li>
              ))}
            </Ul>
            {loading && <LoadingSection />}
          </Row>
        </Rows>
      </ContainerBox>

      <Outlet />
    </FullLayout>
  )
}
