import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { useMutation, useQuery } from '@apollo/client'
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { pick } from '@/utils/helpers'
import { Drawer } from '@/components/drawer'
import { LoadingSection } from '@/components/loading-section'
import { EditInvoiceForm, FormValues } from './components/edit-invoice-form'

export const editInvoiceDocument = graphql(`
  query EditInvoice($id: ID!) {
    invoice(id: $id) {
      id
      issueDate
      dueDate
      sequenceNumber
      description
      client {
        id
      }
      project {
        id
      }
      projectControl {
        id
      }
      recipient {
        name
        contactPerson
        email
        address {
          streetAddress
          postalCode
          postalArea
        }
      }
      items {
        results {
          id
          description
          unitPrice
          quantity
          vatRate
          product {
            id
          }
        }
      }
    }
  }
`)

const updateInvoiceDocument = graphql(`
  mutation UpdateInvoice($id: ID!, $input: InvoiceInput!) {
    updateInvoice(id: $id, input: $input) {
      message
      invoice {
        id
      }
    }
  }
`)

export function EditInvoiceRoute() {
  const params = z.object({ invoiceId: z.string() }).parse(useParams())
  const navigate = useNavigate()
  const toast = useToast()
  const onClose = useCallback(() => navigate('..'), [navigate])

  const { data, loading, error } = useQuery(editInvoiceDocument, {
    variables: { id: params.invoiceId },
  })

  const [updateInvoice, { loading: isSubmitting }] = useMutation(
    updateInvoiceDocument,
    {
      onCompleted(data) {
        toast({
          status: 'success',
          title: data.updateInvoice.message,
          isClosable: true,
        })
        navigate(`../${params.invoiceId}`)
      },
      onError(error) {
        toast({
          status: 'error',
          title: error.message,
          isClosable: true,
        })
      },
    }
  )

  const onSubmit = useCallback(
    async (input: FormValues) => {
      await updateInvoice({ variables: { id: params.invoiceId, input } })
    },
    [updateInvoice, params.invoiceId]
  )

  if (error) throw error

  const invoice = data?.invoice

  return (
    <Drawer
      size="xl"
      title="Endre faktura"
      onClose={onClose}
      footer={
        <ButtonGroup>
          <Button onClick={() => navigate(`../${params.invoiceId}`)}>
            Avbryt
          </Button>
          <Button
            form="invoice-form"
            type="submit"
            colorScheme="blue"
            isLoading={isSubmitting}
          >
            Lagre faktura
          </Button>
        </ButtonGroup>
      }
    >
      {loading && <LoadingSection />}
      {!!invoice && (
        <EditInvoiceForm
          onSubmit={onSubmit}
          defaultValues={{
            ...pick(
              invoice,
              'id',
              'issueDate',
              'dueDate',
              'sequenceNumber',
              'description'
            ),
            clientId: invoice.client.id,
            projectId: invoice.project?.id,
            projectControlId: invoice.projectControl?.id,
            dueDate: invoice.dueDate ?? undefined,
            recipient: {
              ...pick(invoice.recipient, 'name', 'contactPerson', 'email'),
              email: invoice.recipient.email ?? undefined,
              address: pick(
                invoice.recipient.address,
                'streetAddress',
                'postalCode',
                'postalArea'
              ),
            },
            items: invoice.items.results.map((item) => ({
              ...pick(
                item,
                'id',
                'description',
                'unitPrice',
                'quantity',
                'vatRate'
              ),
              productId: item.product?.id,
            })),
          }}
        />
      )}
    </Drawer>
  )
}
