import { forwardRef } from '@chakra-ui/react'
import { Input, InputProps } from '../input'

type Value = string | null

type Props = { value?: Value; onChange: (value: Value) => void } & Omit<
  InputProps,
  'value' | 'onChange'
>

export const DateInput = forwardRef(
  ({ value, onChange, ...rest }: Props, ref) => {
    return (
      <Input
        ref={ref}
        type="date"
        value={value ?? ''}
        onChange={(e) => onChange(e.target.value ?? null)}
        {...rest}
      />
    )
  }
)
