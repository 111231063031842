import { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { HelmetProvider } from 'react-helmet-async'
import { ApolloProvider } from '@apollo/client'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { createApolloClient } from '@/lib/create-apollo-client'
import useVersionCheck from '@/hooks/use-version-check'
import { ErrorBoundary } from '@/components/error-boundary'
import { ViewerContextProvider } from '@/components/viewer-context-provider'
import { RootRoutes } from '@/routes'
import { config } from '@/config'

Sentry.init({
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  ...config.sentry,
})

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: '#F4F6F8',
        fontSize: 'var(--font-size-md)',
      },
    },
  },
  fontSizes: {
    xl: 'var(--font-size-xl)',
    lg: 'var(--font-size-lg)',
    md: 'var(--font-size-md)',
    sm: 'var(--font-size-sm)',
    xs: 'var(--font-size-xs)',
  },
  components: {
    Link: {
      baseStyle: {
        color: 'rgb(51, 122, 183)',
      },
    },
    Input: {
      defaultProps: {
        errorBorderColor: 'red.300',
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: 400,
      },
    },
  },
})

export function App() {
  const { customFetch } = useVersionCheck()
  const [apolloClient] = useState(() =>
    createApolloClient({ fetch: customFetch })
  )

  return (
    <ChakraProvider theme={theme}>
      <ErrorBoundary>
        <HelmetProvider>
          <ApolloProvider client={apolloClient}>
            <ViewerContextProvider>
              <BrowserRouter>
                <RootRoutes />
              </BrowserRouter>
            </ViewerContextProvider>
          </ApolloProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </ChakraProvider>
  )
}
