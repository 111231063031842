import { useCallback } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { useQuery } from '@apollo/client'
import { Button, ButtonGroup } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { config } from '@/config'
import { Drawer } from '@/components/drawer'
import { formatCurrency, formatDate } from '@/utils/format'
import { Divider, Row, Rows } from '@/components/rows'
import { Column, Columns } from '@/components/columns'
import { LabelledContent } from '@/components/labelled-content'
import { Pre } from '@/components/pre'
import { Table, Thead, Tbody, Tr, Th, Td } from '@/components/table'
import { LoadingSection } from '@/components/loading-section'

const invoiceDocument = graphql(`
  query Invoice($id: ID!) {
    invoice(id: $id) {
      id
      type
      state
      publicRef
      issueDate
      dueDate
      sum
      vat
      total
      description
      canEdit
      client {
        id
        name
      }
      recipient {
        contactPerson
        email
        address {
          streetAddress
          postalCode
          postalArea
        }
      }
      items {
        results {
          id
          description
          unitPrice
          quantity
          vat
        }
      }
      credit {
        id
      }
      notices {
        id
        type
      }
    }
  }
`)

export function InvoiceRoute() {
  const params = z.object({ invoiceId: z.string() }).parse(useParams())
  const navigate = useNavigate()
  const onClose = useCallback(() => navigate('..'), [navigate])

  const { data, loading, error } = useQuery(invoiceDocument, {
    variables: { id: params.invoiceId },
  })

  if (error) throw error

  const invoice = data?.invoice
  const collectionNotice = invoice?.notices.find(
    ({ type }) => type === 'collection_notice'
  )
  const term = invoice?.type === 'credit' ? 'Kreditnota' : 'Faktura'

  return (
    <Drawer
      size="xl"
      title={invoice ? `${term} ${invoice.publicRef}` : ''}
      onClose={onClose}
    >
      {loading && <LoadingSection />}
      {!!invoice && (
        <Rows>
          <Row>
            <ButtonGroup size="sm">
              {invoice.canEdit && (
                <Button as={Link} to="./edit">
                  Endre
                </Button>
              )}
              <Button
                as={Link}
                to={`${config.pdfPreviewUrl}/invoices/${invoice.id}`}
                target="_blank"
              >
                Vis PDF
              </Button>
              {invoice.credit ? (
                <Button as={Link} to={`../${invoice.credit.id}`}>
                  Vis kreditnota
                </Button>
              ) : invoice.type === 'invoice' && invoice.canEdit ? (
                <Button as={Link} to="./credit">
                  Krediter
                </Button>
              ) : null}

              {collectionNotice ? (
                <Button
                  as={Link}
                  to={`${config.pdfPreviewUrl}/notices/${collectionNotice.id}`}
                  target="_blank"
                >
                  Vis inkassovarsel (PDF)
                </Button>
              ) : invoice.type === 'invoice' && invoice.canEdit ? (
                <Button as={Link} to="./collection-notice">
                  Lag inkassovarsel
                </Button>
              ) : null}
            </ButtonGroup>
          </Row>
          <Divider />
          <Row>
            <Columns>
              <Column>
                <Rows>
                  <Row>
                    <LabelledContent label={`${term}nummer`}>
                      {invoice.publicRef}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Kunde">
                      {invoice.client.name}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Kontakt">
                      {invoice.recipient.contactPerson}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Adresse">
                      {invoice.recipient.address.streetAddress},{' '}
                      {invoice.recipient.address.postalCode}{' '}
                      {invoice.recipient.address.postalArea}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="E-post">
                      {invoice.recipient.email ?? ''}
                    </LabelledContent>
                  </Row>
                </Rows>
              </Column>
              <Column>
                <Rows>
                  <Row>
                    <LabelledContent label="Dato">
                      {formatDate(invoice.issueDate)}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Forfall">
                      {invoice.dueDate ? formatDate(invoice.dueDate) : ''}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Sum">
                      {formatCurrency(invoice.sum)}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="MVA">
                      {formatCurrency(invoice.vat)}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Total">
                      {formatCurrency(invoice.total)}
                    </LabelledContent>
                  </Row>
                </Rows>
              </Column>
            </Columns>
          </Row>
          <Row>
            <Pre>{invoice.description}</Pre>
          </Row>
          <Row>
            <Table>
              <Thead>
                <Tr>
                  <Th>Varelinje</Th>
                  <Th>Enhetspris</Th>
                  <Th>Antall</Th>
                  <Th>MVA</Th>
                </Tr>
              </Thead>
              <Tbody>
                {invoice.items.results.map((item) => (
                  <Tr key={item.id}>
                    <Td>{item.description}</Td>
                    <Td>{formatCurrency(item.unitPrice)}</Td>
                    <Td>{item.quantity}</Td>
                    <Td>{formatCurrency(item.vat)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Row>
        </Rows>
      )}
    </Drawer>
  )
}
