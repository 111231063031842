import { LoadingIcon } from '@/assets/icons/loading'
import './index.scss'

export function LoadingSection() {
  return (
    <section className="loading-section">
      <LoadingIcon />
    </section>
  )
}
