import './index.scss'

type Props = {
  title: React.ReactNode
  actions?: React.ReactNode
}

export function Heading({ title, actions }: Props) {
  return (
    <div className="heading">
      <h1>{title}</h1>
      {!!actions && <div className="heading--actions">{actions}</div>}
    </div>
  )
}
