import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { useMutation } from '@apollo/client'
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import { format } from 'date-fns'
import { graphql } from '@/__generated__/gql'
import { Drawer } from '@/components/drawer'
import { CreditInvoiceForm, FormValues } from './components/credit-invoice-form'

const creditInvoiceCreditDocument = graphql(`
  mutation CreateInvoiceCredit($input: InvoiceInput!) {
    createInvoice(input: $input) {
      message
      invoice {
        id
      }
    }
  }
`)

export function CreditInvoiceRoute() {
  const params = z.object({ invoiceId: z.string() }).parse(useParams())
  const navigate = useNavigate()
  const toast = useToast()
  const onClose = useCallback(() => navigate('..'), [navigate])

  const [createInvoice, { loading: isSubmitting }] = useMutation(
    creditInvoiceCreditDocument,
    {
      refetchQueries: ['Invoices'],
      onCompleted(data) {
        toast({
          status: 'success',
          title: data.createInvoice.message,
          isClosable: true,
        })
        navigate(`../${data.createInvoice.invoice.id}`)
      },
      onError(error) {
        toast({
          status: 'error',
          title: error.message,
          isClosable: true,
        })
      },
    }
  )

  const onSubmit = useCallback(
    async (input: FormValues) => {
      await createInvoice({
        variables: {
          input: { type: 'credit', parentId: params.invoiceId, ...input },
        },
      })
    },
    [createInvoice]
  )

  return (
    <Drawer
      title="Krediter faktura"
      onClose={onClose}
      footer={
        <ButtonGroup>
          <Button onClick={() => navigate(`../${params.invoiceId}`)}>
            Avbryt
          </Button>
          <Button
            form="credit-invoice-form"
            type="submit"
            colorScheme="blue"
            isLoading={isSubmitting}
          >
            Lag kreditnota
          </Button>
        </ButtonGroup>
      }
    >
      <CreditInvoiceForm
        onSubmit={onSubmit}
        defaultValues={{
          issueDate: format(new Date(), 'Y-MM-dd'),
        }}
      />
    </Drawer>
  )
}
