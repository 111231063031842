import { Link as RouteLink, Outlet } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Button, ButtonGroup, Link } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { formatCurrency, formatDate } from '@/utils/format'
import { useQueryParams } from '@/hooks/use-query-params'
import { usePagination } from '@/hooks/use-pagination'
import { FullLayout } from '@/components/full-layout'
import { ContainerBox } from '@/components/container-box'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/components/table'
import { Row, Rows } from '@/components/rows'
import { Heading } from '@/components/heading'
import { DownloadExportButton } from '@/components/download-export-button'
import { SortDirectionLink } from '@/components/sort-direction-link'
import { LoadingSection } from '@/components/loading-section'

export const invoicesDocument = graphql(`
  query Invoices($input: ConnectionInputArgs) {
    invoices(input: $input) {
      totalCount
      results {
        id
        publicRef
        type
        issueDate
        sum
        total
        client {
          id
          name
        }
        project {
          id
          category {
            id
            name
          }
        }
      }
    }
  }
`)

export function InvoicesRoute() {
  const [queryParams] = useQueryParams()
  const { Pagination, variables } = usePagination()
  const { data, loading, error } = useQuery(invoicesDocument, {
    variables: {
      input: {
        ...variables,
        ...queryParams,
        page: queryParams.page ? Number(queryParams.page) : undefined,
      },
    },
  })
  const invoices = data?.invoices.results || []
  const totalCount = data?.invoices.totalCount || 0

  if (error) throw error

  return (
    <FullLayout>
      <ContainerBox>
        <Rows>
          <Row>
            <Heading
              title="Faktura"
              actions={
                <ButtonGroup>
                  <DownloadExportButton
                    label="Last ned (Excel)"
                    file="invoices.xlsx"
                  />
                  <Button as={RouteLink} to="new" size="sm" colorScheme="blue">
                    Ny faktura +
                  </Button>
                </ButtonGroup>
              }
            />
          </Row>
          <Row>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <SortDirectionLink field="invoice.createdAt" label="#" />
                  </Th>
                  <Th>
                    <SortDirectionLink field="invoice.type" label="Type" />
                  </Th>
                  <Th>
                    <SortDirectionLink field="client.name" label="Kunde" />
                  </Th>
                  <Th>
                    <SortDirectionLink field="category.name" label="Kategori" />
                  </Th>
                  <Th>
                    <SortDirectionLink field="invoice.issueDate" label="Dato" />
                  </Th>
                  <Th>
                    <SortDirectionLink
                      field="invoice.sum"
                      label="Beløp eks.mva"
                    />
                  </Th>
                  <Th>Beløp ink.mva</Th>
                </Tr>
              </Thead>
              <Tbody>
                {invoices.map((invoice) => (
                  <Tr key={invoice.id}>
                    <Td>
                      <Link as={RouteLink} to={invoice.id}>
                        {invoice.publicRef}
                      </Link>
                    </Td>
                    <Td>{invoice.type}</Td>
                    <Td>{invoice.client.name}</Td>
                    <Td>{invoice.project?.category.name}</Td>
                    <Td>{formatDate(invoice.issueDate)}</Td>
                    <Td>{formatCurrency(invoice.sum)}</Td>
                    <Td>{formatCurrency(invoice.total)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {loading && <LoadingSection />}
          </Row>
          {totalCount > variables.limit && (
            <Row>
              <Pagination total={totalCount} />
            </Row>
          )}
        </Rows>
      </ContainerBox>

      <Outlet />
    </FullLayout>
  )
}
