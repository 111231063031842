import { useForm } from 'react-hook-form'
import { Button, FormLabel, FormControl } from '@chakra-ui/react'
import { Row, Rows } from '@/components/rows'
import { Input } from '@/components/input'

type Props = {
  onSubmit: (input: FormValues) => Promise<void> | void
  defaultValues?: Partial<FormValues>
}

export type FormValues = {
  issueDate: string
}

export function CreditInvoiceForm({ defaultValues, onSubmit }: Props) {
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues,
  })

  return (
    <form id="credit-invoice-form" onSubmit={handleSubmit(onSubmit)}>
      <Rows>
        <Row>
          <FormControl isRequired>
            <FormLabel>Dato</FormLabel>
            <Input type="date" {...register('issueDate', { required: true })} />
          </FormControl>
        </Row>
      </Rows>
    </form>
  )
}
