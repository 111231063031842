import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { z } from 'zod'
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { Drawer } from '@/components/drawer'
import { LoadingSection } from '@/components/loading-section'
import { ProjectForm, FormValues } from './components/project-form'

export const projectFragmentDocument = graphql(`
  fragment ProjectFragment on Project {
    id
    publicRef
    invoicePolicy
    category { id }
    feeInPercentage
    date
    currentSupplierName
    estimatedBenchmark
    analysisMeetingDate
    reportDate
    reportApprovedDate
    reportBenchmark
    reportSavings
    reportSavingsPercentage
    newSupplierName
    newSupplierStartDate
    agreementUrl
    approvedReportUrl
  }
`)

const editProjectDocument = graphql(`
  query EditProject($id: ID!) {
    project(id: $id) {
      ...ProjectFragment
    }
  }
`)

const updateProjectDocument = graphql(`
  mutation UpdateProject($id: ID!, $input: ProjectInput!) {
    updateProject(id: $id, input: $input) {
      message
      project {
        ...ProjectFragment
      }
    }
  }
`)

export function EditProjectRoute() {
  const params = z
    .object({ clientId: z.string(), projectId: z.string() })
    .parse(useParams())
  const navigate = useNavigate()
  const toast = useToast()
  const onClose = useCallback(() => navigate('..'), [navigate])
  const { data, loading, error } = useQuery(editProjectDocument, {
    variables: { id: params.projectId },
  })
  const [updateProject, { loading: isUpdating }] = useMutation(
    updateProjectDocument,
    {
      onCompleted(data) {
        toast({
          status: 'success',
          title: data.updateProject.message,
          isClosable: true,
        })
        onClose()
      },
      onError(error) {
        toast({
          status: 'error',
          title: error.message,
          isClosable: true,
        })
      },
    }
  )

  const onSubmit = async (input: FormValues) => {
    await updateProject({
      variables: { id: params.projectId, input },
    })
  }

  if (error) throw error

  const project = data?.project

  return (
    <Drawer
      title={`Endre prosjekt ${project?.publicRef || ''}`}
      onClose={onClose}
      size="lg"
      footer={
        <ButtonGroup>
          <Button onClick={onClose}>Avbryt</Button>
          <Button
            form="project-form"
            type="submit"
            colorScheme="blue"
            isLoading={isUpdating}
          >
            Lagre prosjekt
          </Button>
        </ButtonGroup>
      }
    >
      {loading && <LoadingSection />}
      {!!project && (
        <ProjectForm
          onSubmit={onSubmit}
          defaultValues={{
            categoryId: project.category.id,
            invoicePolicy: project.invoicePolicy!,
            feeInPercentage: project.feeInPercentage,
            date: project.date,
            currentSupplierName: project.currentSupplierName,
            estimatedBenchmark: project.estimatedBenchmark,
            analysisMeetingDate: project.analysisMeetingDate,
            reportDate: project.reportDate,
            reportApprovedDate: project.reportApprovedDate,
            reportBenchmark: project.reportBenchmark,
            reportSavings: project.reportSavings,
            reportSavingsPercentage: project.reportSavingsPercentage,
            newSupplierName: project.newSupplierName,
            newSupplierStartDate: project.newSupplierStartDate,
            agreementUrl: project.agreementUrl,
            approvedReportUrl: project.approvedReportUrl,
          }}
        />
      )}
    </Drawer>
  )
}
