import { Routes, Route } from 'react-router-dom'
import { CollectionNoticeInvoiceRoute } from './collection-notice'
import { CreditInvoiceRoute } from './credit'
import { EditInvoiceRoute } from './edit'
import { InvoiceRoute } from './invoice'
import { InvoicesRoute } from './invoices'
import { NewInvoiceRoute } from './new'

export function InvoicesRoutes() {
  return (
    <Routes>
      <Route path="/" element={<InvoicesRoute />}>
        <Route path="new" element={<NewInvoiceRoute />} />
        <Route path=":invoiceId/edit" element={<EditInvoiceRoute />} />
        <Route path=":invoiceId/credit" element={<CreditInvoiceRoute />} />
        <Route
          path=":invoiceId/collection-notice"
          element={<CollectionNoticeInvoiceRoute />}
        />
        <Route path=":invoiceId" element={<InvoiceRoute />} />
      </Route>
    </Routes>
  )
}
