import './index.scss'

type Props = {
  label: React.ReactNode
  children?: React.ReactNode
}

export function LabelledContent({ label, children }: Props) {
  return (
    <div className="labelled-content">
      <div className="labelled-content--label">{label}</div>
      <div className="labelled-content--content">{children}</div>
    </div>
  )
}
