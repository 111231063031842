import { useQuery } from '@apollo/client'
import { Controller, useForm } from 'react-hook-form'
import { FormLabel, FormControl, Select } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { Row, Rows } from '@/components/rows'
import { CategorySelect } from '@/components/category-select'
import { DateInput } from '@/components/date-input'
import { SupplierSelect } from '@/components/supplier-select'
import { FileUploadInput } from '@/components/file-upload-input'

type Props = {
  onSubmit: (input: FormValues) => Promise<void> | void
  defaultValues?: Partial<FormValues>
}

export type FormValues = {
  date: string
  supplierId: string
  categoryId: string
  costSizeId: string
  url: string
}

const offerCostSizesDocument = graphql(`
  query OfferCostSizes {
    offerCostSizes {
      results {
        id
        name
      }
    }
  }
`)

export function OfferForm({ defaultValues, onSubmit }: Props) {
  const { data } = useQuery(offerCostSizesDocument)
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues,
  })

  const offerCostSizes = data?.offerCostSizes.results || []

  return (
    <form id="offer-form" onSubmit={handleSubmit(onSubmit)}>
      <Rows>
        <Row>
          <FormControl isRequired>
            <FormLabel>Dato</FormLabel>
            <Controller
              control={control}
              name="date"
              rules={{ required: true }}
              render={({ field }) => (
                <DateInput
                  value={field.value}
                  onChange={(v) => field.onChange(v ?? '')}
                />
              )}
            />
          </FormControl>
        </Row>
        <Row>
          <FormControl isRequired>
            <FormLabel>Leverandør</FormLabel>
            <Controller
              control={control}
              name="supplierId"
              rules={{ required: true }}
              render={({ field }) => (
                <SupplierSelect
                  prompt="Velg leverandør"
                  value={field.value}
                  onChange={(v) => field.onChange(v ?? '')}
                />
              )}
            />
          </FormControl>
        </Row>
        <Row>
          <FormControl isRequired>
            <FormLabel>Kategori</FormLabel>
            <Controller
              control={control}
              name="categoryId"
              rules={{ required: true }}
              render={({ field }) => (
                <CategorySelect
                  prompt="Velg kategori"
                  value={field.value}
                  onChange={(v) => field.onChange(v ?? '')}
                />
              )}
            />
          </FormControl>
        </Row>
        <Row>
          <FormControl isRequired>
            <FormLabel>Kategoristørrelse</FormLabel>
            <Controller
              control={control}
              name="costSizeId"
              rules={{ required: true }}
              render={({ field }) => (
                <Select value={field.value} onChange={field.onChange}>
                  <option>Velg kategoristørrelse</option>
                  {offerCostSizes.map((costSize) => (
                    <option key={costSize.id} value={costSize.id}>
                      {costSize.name}
                    </option>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Row>
        <Row>
          <FormControl>
            <FormLabel>Fil</FormLabel>
            <Controller
              control={control}
              name="url"
              render={({ field }) => (
                <FileUploadInput
                  value={field.value}
                  onChange={(v) => field.onChange(v ?? '')}
                />
              )}
            />
          </FormControl>
        </Row>
        <Row>
          <p>
            Last opp en Excel-fil med følgende kolonner (A-D):
            <br />
            Varenummer, Varetekst, Enhet, Enhetspris.
          </p>
          <p>
            Første rad (1) må være overskrifter, så selve varelinjene starter på
            linje 2.
          </p>
          <p>
            Enhetspriser skal være formatert som regnskap med to desimaler og
            mellomrom som tusentegnskille.
          </p>
        </Row>
      </Rows>
    </form>
  )
}
