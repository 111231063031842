import { useRef, useState, useEffect } from 'react'
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { MdOutlineSearch } from 'react-icons/md'
import cn from 'classnames'
import './index.scss'

type Props = {
  query?: string
  onChange: (query: string) => void
  placeholder?: string
}

export function SearchButtonField({
  query = '',
  onChange,
  placeholder,
}: Props) {
  const [searchQuery, setSearchQuery] = useState(query)
  const inputEl = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (searchQuery === query) return
    const handler = setTimeout(() => {
      onChange(searchQuery)
    }, 200)
    return () => clearTimeout(handler)
  }, [searchQuery])

  const focusTextField = () => {
    inputEl.current?.focus()
  }

  return (
    <div className={cn('search-button-field', { open: !!searchQuery })}>
      <InputGroup size="sm">
        <Input
          ref={inputEl}
          placeholder={placeholder}
          value={searchQuery || ''}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <InputRightElement onClick={focusTextField}>
          <MdOutlineSearch />
        </InputRightElement>
      </InputGroup>
    </div>
  )
}
