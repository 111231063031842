import { Routes, Route } from 'react-router-dom'
import { DocumentsRoute } from './documents'
import { NewDocumentRoute } from './new'

export function DocumentsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<DocumentsRoute />}>
        <Route path="new" element={<NewDocumentRoute />} />
      </Route>
    </Routes>
  )
}
