import { useCallback, useRef } from 'react'
import { Button, ButtonProps } from '@chakra-ui/react'
import './index.scss'

type Props = {
  onFiles: (files: FileList) => void | Promise<void>
} & ButtonProps

export function FileInputButton({ children, onFiles, ...rest }: Props) {
  const fileInput = useRef<HTMLInputElement>(null)

  const handleFiles = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files
      if (files) onFiles(files)
    },
    [onFiles]
  )

  const handleClick = useCallback(() => {
    fileInput.current?.click()
  }, [])

  return (
    <>
      <Button {...rest} onClick={handleClick}>
        {children}
      </Button>
      <input
        ref={fileInput}
        className="file-input-button--input"
        type="file"
        onChange={handleFiles}
      />
    </>
  )
}
