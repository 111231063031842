import { Controller, useForm } from 'react-hook-form'
import { FormLabel, FormControl, Textarea } from '@chakra-ui/react'
import { Input } from '@/components/input'
import { Row, Rows } from '@/components/rows'
import { AddressFormFields } from '@/components/address-form-fields'
import { Column, Columns } from '@/components/columns'
import { CategoriesSelect } from '@/components/categories-select'

type Props = {
  onSubmit: (input: FormValues) => Promise<void> | void
  defaultValues?: Partial<FormValues>
}

export type FormValues = {
  name: string
  companyName: string
  email: string
  mobile: string
  services: string
  comment: string
  address: {
    streetAddress: string
    postalCode: string
    postalArea: string
  }
  categories: { id: string }[]
}

export function SpecialistForm({ defaultValues, onSubmit }: Props) {
  const { register, control, handleSubmit } = useForm<FormValues>({
    defaultValues,
  })

  return (
    <form id="specialist-form" onSubmit={handleSubmit(onSubmit)}>
      <Rows>
        <Row>
          <Columns>
            <Column>
              <Rows>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>Navn</FormLabel>
                    <Input {...register('name', { required: true })} />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>E-post</FormLabel>
                    <Input {...register('email', { required: true })} />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>Mobil</FormLabel>
                    <Input {...register('mobile', { required: true })} />
                  </FormControl>
                </Row>
                <Row>
                  <AddressFormFields isRequired register={register} />
                </Row>
              </Rows>
            </Column>
            <Column>
              <Rows>
                <Row>
                  <FormControl>
                    <FormLabel>Bedrift</FormLabel>
                    <Input {...register('companyName')} />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Tjenester</FormLabel>
                    <Textarea rows={2} {...register('services')} />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Kommentar</FormLabel>
                    <Textarea rows={2} {...register('comment')} />
                  </FormControl>
                </Row>
              </Rows>
            </Column>
          </Columns>
        </Row>
        <Row>
          <FormControl>
            <FormLabel>Kategorier</FormLabel>
            <Controller
              control={control}
              name="categories"
              render={({ field: { value, onChange } }) => (
                <CategoriesSelect
                  values={value?.map(({ id }) => id) || []}
                  onChange={(ids) => onChange(ids.map((id) => ({ id })))}
                />
              )}
            />
          </FormControl>
        </Row>
      </Rows>
    </form>
  )
}
