import { useDevice } from '@/hooks/use-device'
import './index.scss'

interface Props {
  children?: React.ReactNode
}

export function AuthLayout({ children }: Props) {
  const device = useDevice()

  return (
    <div className={`auth-layout layout--${device}`}>
      <div className="auth-layout--wrapper">
        <main className="auth-layout--main">{children}</main>
      </div>
    </div>
  )
}
