import { Routes, Route } from 'react-router-dom'
import { CategoriesRoute } from './categories'

export function CategoriesRoutes() {
  return (
    <Routes>
      <Route path="/" element={<CategoriesRoute />} />
    </Routes>
  )
}
