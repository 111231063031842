import { useContext, createContext, ReactNode } from 'react'
import { useQuery } from '@apollo/client'
import { graphql, DocumentType } from '@/__generated__/gql'
import { FallbackError } from '../error-boundary/fallback-error'
import { LoadingSection } from '../loading-section'

export const viewerContextProviderDocument = graphql(`
  query ViewerContextProvider {
    viewer {
      id
      instance {
        id
        name
      }
      user {
        id
        name
        instances {
          id
          name
        }
      }
      roles
    }
  }
`)

type ViewerContext = { isAdmin: boolean } & DocumentType<
  typeof viewerContextProviderDocument
>['viewer']

const ViewerContext = createContext<ViewerContext | null>(null)

export function ViewerContextProvider({ children }: { children: ReactNode }) {
  const { data, loading, error } = useQuery(viewerContextProviderDocument)

  if (loading) return <LoadingSection />
  if (error || !data) {
    return <FallbackError error={error || new Error('Error loading viewer')} />
  }

  const isAdmin = data.viewer.roles.includes('admin')

  return (
    <ViewerContext.Provider value={{ ...data.viewer, isAdmin }}>
      {children}
    </ViewerContext.Provider>
  )
}

export function useViewer() {
  return useContext(ViewerContext) as ViewerContext
}
