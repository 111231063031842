import { forwardRef } from 'react'
import { Input, InputProps } from '@chakra-ui/react'
import { NumericFormat, NumberFormatValues } from 'react-number-format'

type Value = number | null

export type NumberInputProps = {
  decimalSeparator?: string
  defaultValue?: Value
  value?: Value
  onChange?: (value: Value) => void
  max?: number | string
  min?: number | string
  precision?: number
} & Omit<InputProps, 'value' | 'defaultValue' | 'onChange' | 'type'>

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    { decimalSeparator = ',', precision = 0, max, min, onChange, ...props },
    ref
  ) => {
    const isAllowed = ({ floatValue }: NumberFormatValues) => {
      if (floatValue === undefined) return true
      if (min !== undefined && Number(floatValue) < Number(min)) return false
      if (max !== undefined && Number(floatValue) > Number(max)) return false
      return true
    }

    const allowNegative = typeof min === 'undefined' || Number(min) < 0

    return (
      <NumericFormat
        valueIsNumericString
        getInputRef={ref}
        customInput={Input}
        allowNegative={allowNegative}
        thousandSeparator={' '}
        decimalSeparator={decimalSeparator}
        decimalScale={precision}
        inputMode={precision === 0 ? 'numeric' : 'decimal'}
        isAllowed={isAllowed}
        onValueChange={({ floatValue }) => {
          onChange?.(floatValue ?? null)
        }}
        {...props}
      />
    )
  }
)
