export function LoadingIcon() {
  return (
    <svg
      height="3em"
      width="38"
      className="icon-loading"
      viewBox="0 0 38 38"
      overflow="visible"
    >
      <g
        transform="translate(1 1)"
        fill="none"
        fillRule="evenodd"
        stroke="rgb(51, 122, 183)"
        strokeWidth="3"
        strokeOpacity="1"
      >
        <circle strokeOpacity="0.5" cx="18" cy="18" r="18"></circle>
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </svg>
  )
}
