import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { useMutation, useQuery } from '@apollo/client'
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { Drawer } from '@/components/drawer'
import { SubmitError } from '@/components/submit-error'
import { LoadingSection } from '@/components/loading-section'
import { UserForm, FormValues } from './components/user-form'

const editUserDocument = graphql(`
  query EditUser($id: ID!) {
    user(id: $id) {
      id
      name
      email
      mobile
      address {
        streetAddress
        postalCode
        postalArea
      }
    }
  }
`)

const updateUserDocument = graphql(`
  mutation UpdateUser($id: ID!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      message
      user {
        id
        name
        email
        mobile
        address {
          streetAddress
          postalCode
          postalArea
        }
      }
    }
  }
`)

export function EditUserRoute() {
  const params = z.object({ userId: z.string() }).parse(useParams())
  const navigate = useNavigate()
  const toast = useToast()
  const onCancel = useCallback(
    () => navigate(`../${params.userId}`),
    [navigate, params]
  )

  const { data, loading, error } = useQuery(editUserDocument, {
    variables: { id: params.userId },
  })
  const [updateUser, { loading: submitting, error: submitError }] = useMutation(
    updateUserDocument,
    {
      onError() {},
      onCompleted(data) {
        toast({
          status: 'success',
          title: data?.updateUser.message,
          isClosable: true,
        })
        onCancel()
      },
    }
  )

  const onSubmit = useCallback(
    async (input: FormValues) => {
      await updateUser({
        variables: { id: params.userId, input },
      })
    },
    [updateUser, params]
  )

  if (error) throw error

  const user = data?.user

  return (
    <Drawer
      title="Endre bruker"
      onClose={() => navigate('..')}
      footer={
        <ButtonGroup>
          <Button onClick={onCancel}>Avbryt</Button>
          <Button
            form="user-form"
            type="submit"
            colorScheme="blue"
            isLoading={submitting}
          >
            Lagre bruker
          </Button>
        </ButtonGroup>
      }
    >
      <SubmitError error={submitError} />
      {loading && <LoadingSection />}
      {!!user && (
        <UserForm
          onSubmit={onSubmit}
          defaultValues={{
            name: user.name,
            email: user.email,
            mobile: user.mobile,
            address: {
              streetAddress: user.address.streetAddress,
              postalCode: user.address.postalCode,
              postalArea: user.address.postalArea,
            },
          }}
        />
      )}
    </Drawer>
  )
}
