import { useCallback, useState } from 'react'

export default function useVersionCheck({
  versionUrl = '/version.txt',
  interval = 30 * 1000,
} = {}) {
  const [hasNewVersion, setHasNewVersion] = useState(false)

  let isChecking = false
  let lastCheck: Date | undefined
  let currentVersion: string | undefined

  const customFetch = useCallback(
    async (uri: RequestInfo, options: RequestInit) => {
      const now = new Date()
      const timeOutsideInterval =
        lastCheck && now.getTime() - lastCheck.getTime() >= interval

      if (!isChecking && (!lastCheck || timeOutsideInterval)) {
        isChecking = true
        const lastVersion = await fetch(versionUrl).then((res) => res.text())
        lastCheck = now
        isChecking = false
        if (currentVersion && currentVersion !== lastVersion) {
          setHasNewVersion(true)
        }
        currentVersion = lastVersion
      }

      return fetch(uri, options)
    },
    [versionUrl, interval]
  )

  return { customFetch, hasNewVersion }
}
