import { useCallback } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { useQuery } from '@apollo/client'
import { Button, ButtonGroup } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { Drawer } from '@/components/drawer'
import { Row, Rows } from '@/components/rows'
import { LabelledContent } from '@/components/labelled-content'
import { ActionsBar } from '@/components/actions-bar'
import { LoadingSection } from '@/components/loading-section'

const specialistDocument = graphql(`
  query Specialist($id: ID!) {
    specialist(id: $id) {
      id
      name
      email
      mobile
      companyName
      services
      comment
      createdAt
      updatedAt
      canEdit
      address {
        streetAddress
        postalCode
        postalArea
      }
      categories {
        id
        name
      }
    }
  }
`)

export function SpecialistRoute() {
  const params = z.object({ specialistId: z.string() }).parse(useParams())
  const navigate = useNavigate()
  const onClose = useCallback(() => navigate('..'), [navigate])

  const { data, loading, error } = useQuery(specialistDocument, {
    variables: { id: params.specialistId },
  })

  if (error) throw error

  const specialist = data?.specialist

  return (
    <Drawer size="lg" title={specialist?.name || ''} onClose={onClose}>
      {loading && <LoadingSection />}
      {!!specialist && (
        <Rows>
          {specialist.canEdit && (
            <Row>
              <ActionsBar>
                <ButtonGroup size="sm">
                  <Button as={Link} to="./edit">
                    Endre
                  </Button>
                </ButtonGroup>
              </ActionsBar>
            </Row>
          )}
          <Row>
            <LabelledContent label="Telefon">
              {specialist.mobile || '-'}
            </LabelledContent>
          </Row>
          <Row>
            <LabelledContent label="Email">
              {specialist.email || '-'}
            </LabelledContent>
          </Row>
          <Row>
            <LabelledContent label="Adresse">
              {specialist.address ? (
                <>
                  {specialist.address.streetAddress},{' '}
                  {specialist.address.postalCode}{' '}
                  {specialist.address.postalArea}
                </>
              ) : (
                '-'
              )}
            </LabelledContent>
          </Row>
          <Row>
            <LabelledContent label="Bedrift">
              {specialist.companyName || '-'}
            </LabelledContent>
          </Row>
          <Row>
            <LabelledContent label="Tjenester">
              {specialist.services || '-'}
            </LabelledContent>
          </Row>
          <Row>
            <LabelledContent label="Kommentar">
              {specialist.comment || '-'}
            </LabelledContent>
          </Row>
          <Row>
            <LabelledContent label="Kategorier">
              {specialist.categories.length
                ? specialist.categories.map(({ name }) => name).join(', ')
                : '-'}
            </LabelledContent>
          </Row>
        </Rows>
      )}
    </Drawer>
  )
}
