import { useCallback, useRef } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { z } from 'zod'
import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  ButtonGroup,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useToast,
} from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { formatPhoneNumber } from '@/utils/format'
import { Drawer } from '@/components/drawer'
import { Row, Rows } from '@/components/rows'
import { LabelledContent } from '@/components/labelled-content'
import { ActionsBar } from '@/components/actions-bar'
import { LoadingSection } from '@/components/loading-section'

const userDocument = graphql(`
  query User($id: ID!) {
    user(id: $id) {
      id
      name
      email
      mobile
      canEdit
      address {
        streetAddress
        postalCode
        postalArea
      }
    }
  }
`)

const deactivateUserDocument = graphql(`
  mutation DeactivateUser($id: ID!) {
    deactivateUser(id: $id) {
      message
    }
  }
`)

export function UserRoute() {
  const params = z.object({ userId: z.string() }).parse(useParams())
  const navigate = useNavigate()
  const toast = useToast()
  const deactivateDisclosure = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)
  const onClose = useCallback(() => navigate('..'), [navigate])

  const { data, loading, error } = useQuery(userDocument, {
    variables: { id: params.userId },
  })

  const [deactivateUser, { loading: isDeactivating }] = useMutation(
    deactivateUserDocument,
    {
      refetchQueries: ['Users'],
      onCompleted(data) {
        toast({
          status: 'success',
          title: data.deactivateUser.message,
          isClosable: true,
        })
        onClose()
      },
      onError(error) {
        toast({
          status: 'error',
          title: error.message,
          isClosable: true,
        })
      },
    }
  )

  if (error) throw error

  const user = data?.user

  return (
    <Drawer title={user?.name || ''} onClose={onClose}>
      {loading && <LoadingSection />}
      {!!user && (
        <>
          <Rows>
            {user.canEdit && (
              <Row>
                <ActionsBar>
                  <ButtonGroup size="sm">
                    <Button as={Link} to="./edit">
                      Endre
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={deactivateDisclosure.onOpen}
                    >
                      Deaktiver
                    </Button>
                  </ButtonGroup>
                </ActionsBar>
              </Row>
            )}
            <Row>
              <LabelledContent label="Mobil">
                {formatPhoneNumber(user.mobile)}
              </LabelledContent>
            </Row>
            <Row>
              <LabelledContent label="E-post">{user.email}</LabelledContent>
            </Row>
            <Row>
              <LabelledContent label="Adresse">
                {user.address.streetAddress}, {user.address.postalCode}{' '}
                {user.address.postalArea}
              </LabelledContent>
            </Row>
          </Rows>
          <AlertDialog
            isOpen={deactivateDisclosure.isOpen}
            leastDestructiveRef={cancelRef}
            onClose={deactivateDisclosure.onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Deaktiver bruker
                </AlertDialogHeader>

                <AlertDialogBody>
                  Er du sikker på at du vil deaktivere {user.name}?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button
                    ref={cancelRef}
                    onClick={deactivateDisclosure.onClose}
                  >
                    Avbryt
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() =>
                      deactivateUser({ variables: { id: params.userId } })
                    }
                    ml={3}
                    isLoading={isDeactivating}
                  >
                    Deaktiver
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )}
    </Drawer>
  )
}
