import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { Drawer } from '@/components/drawer'
import { DocumentForm, FormValues } from './components/document-form'

const createDocumentDocument = graphql(`
  mutation CreateDocument($input: DocumentInput!) {
    createDocument(input: $input) {
      message
      document {
        id
      }
    }
  }
`)

export function NewDocumentRoute() {
  const navigate = useNavigate()
  const toast = useToast()
  const onClose = useCallback(() => navigate('..'), [navigate])

  const [createDocument, { loading: isSubmitting }] = useMutation(
    createDocumentDocument,
    {
      refetchQueries: ['Documents'],
      onCompleted(data) {
        toast({
          status: 'success',
          title: data.createDocument.message,
          isClosable: true,
        })
        onClose()
      },
      onError(error) {
        toast({
          status: 'error',
          title: error.message,
          isClosable: true,
        })
      },
    }
  )

  const onSubmit = useCallback(
    async (input: FormValues) => {
      await createDocument({ variables: { input } })
    },
    [createDocument]
  )

  return (
    <Drawer
      title="Nytt dokument"
      onClose={onClose}
      footer={
        <ButtonGroup>
          <Button onClick={onClose}>Avbryt</Button>
          <Button
            form="document-form"
            type="submit"
            colorScheme="blue"
            isLoading={isSubmitting}
          >
            Opprett dokument
          </Button>
        </ButtonGroup>
      }
    >
      <DocumentForm onSubmit={onSubmit} />
    </Drawer>
  )
}
