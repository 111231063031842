import { useForm } from 'react-hook-form'
import { FormLabel, FormControl, FormErrorMessage } from '@chakra-ui/react'
import { EMAIL_PATTERN } from '@/lib/consts'
import { Input } from '@/components/input'
import { AddressFormFields } from '@/components/address-form-fields'
import { Row, Rows } from '@/components/rows'

type Props = {
  onSubmit: (input: FormValues) => Promise<void> | void
  defaultValues?: Partial<FormValues>
}

export type FormValues = {
  name: string
  email: string
  mobile: string
  address: {
    streetAddress: string
    postalCode: string
    postalArea: string
  }
}

export function UserForm({ defaultValues, onSubmit }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues })
  return (
    <form id="user-form" onSubmit={handleSubmit(onSubmit)}>
      <Rows>
        <Row>
          <FormControl isRequired isInvalid={!!errors.name}>
            <FormLabel>Navn</FormLabel>
            <Input {...register('name', { required: true })} />
            {!!errors.name && (
              <FormErrorMessage fontSize="xs">Navn er påkrevd</FormErrorMessage>
            )}
          </FormControl>
        </Row>
        <Row>
          <FormControl isRequired isInvalid={!!errors.email}>
            <FormLabel>E-post</FormLabel>
            <Input
              {...register('email', { required: true, pattern: EMAIL_PATTERN })}
            />
            {!!errors.email && (
              <FormErrorMessage fontSize="xs">
                E-post er påkrevd
              </FormErrorMessage>
            )}
          </FormControl>
        </Row>
        <Row>
          <FormControl isRequired isInvalid={!!errors.mobile}>
            <FormLabel>Mobilnummer</FormLabel>
            <Input {...register('mobile', { required: true })} />
            {!!errors.mobile && (
              <FormErrorMessage fontSize="xs">
                Mobilnummer er påkrevd
              </FormErrorMessage>
            )}
          </FormControl>
        </Row>
        <Row>
          <AddressFormFields isRequired register={register} />
        </Row>
      </Rows>
    </form>
  )
}
