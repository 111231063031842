import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { z } from 'zod'
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { ProjectInvoicePolicy } from '@/__generated__/gql/graphql'
import { Drawer } from '@/components/drawer'
import { ProjectForm, FormValues } from './components/project-form'

const createProjectDocument = graphql(`
  mutation CreateProject($input: ProjectInput!) {
    createProject(input: $input) {
      message
      project {
        id
      }
    }
  }
`)

export function NewProjectRoute() {
  const params = z.object({ clientId: z.string() }).parse(useParams())
  const navigate = useNavigate()
  const toast = useToast()
  const onClose = useCallback(() => navigate('..'), [navigate])

  const [createProject, { loading: isSubmitting }] = useMutation(
    createProjectDocument,
    {
      refetchQueries: ['ClientProjects'],
      onCompleted(data) {
        toast({
          status: 'success',
          title: data.createProject.message,
          isClosable: true,
        })
        onClose()
      },
      onError(error) {
        toast({
          status: 'error',
          title: error.message,
          isClosable: true,
        })
      },
    }
  )

  const onSubmit = async (input: FormValues) => {
    await createProject({
      variables: { input: { clientId: params.clientId, ...input } },
    })
  }

  return (
    <Drawer
      title="Nytt prosjekt"
      onClose={onClose}
      size="lg"
      footer={
        <ButtonGroup>
          <Button onClick={onClose}>Avbryt</Button>
          <Button
            form="project-form"
            type="submit"
            colorScheme="blue"
            isLoading={isSubmitting}
          >
            Opprett prosjekt
          </Button>
        </ButtonGroup>
      }
    >
      <ProjectForm
        onSubmit={onSubmit}
        defaultValues={{ invoicePolicy: ProjectInvoicePolicy.Default }}
      />
    </Drawer>
  )
}
