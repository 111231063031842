import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { useViewer } from '@/components/viewer-context-provider'
import { Drawer } from '@/components/drawer'
import { ClientForm, FormValues } from './components/client-form'

const createClientDocument = graphql(`
  mutation CreateClient($input: ClientInput!) {
    createClient(input: $input) {
      message
      client {
        id
      }
    }
  }
`)

export function NewClientRoute() {
  const viewer = useViewer()
  const navigate = useNavigate()
  const toast = useToast()
  const onClose = useCallback(() => navigate('..'), [navigate])

  const [createClient, { loading: isSubmitting }] = useMutation(
    createClientDocument,
    {
      refetchQueries: ['Clients'],
      onCompleted(data) {
        toast({
          status: 'success',
          title: data.createClient.message,
          isClosable: true,
        })
        navigate(`../${data.createClient.client.id}`)
      },
      onError(error) {
        toast({
          status: 'error',
          title: error.message,
          isClosable: true,
        })
      },
    }
  )

  const onSubmit = async (input: FormValues) => {
    await createClient({ variables: { input } })
  }

  return (
    <Drawer
      title="Ny kunde"
      onClose={onClose}
      size="lg"
      footer={
        <ButtonGroup>
          <Button onClick={onClose}>Avbryt</Button>
          <Button
            form="client-form"
            type="submit"
            colorScheme="blue"
            isLoading={isSubmitting}
          >
            Opprett kunde
          </Button>
        </ButtonGroup>
      }
    >
      <ClientForm
        onSubmit={onSubmit}
        defaultValues={{
          userId: viewer.user?.id,
        }}
      />
    </Drawer>
  )
}
