import { Routes, Route } from 'react-router-dom'
import { SupplierRoute } from './supplier'
import { SuppliersRoute } from './suppliers'
import { NewSupplierRoute } from './new'
import { EditSupplierRoute } from './edit'

export function SuppliersRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SuppliersRoute />}>
        <Route path="new" element={<NewSupplierRoute />} />
        <Route path=":supplierId/edit" element={<EditSupplierRoute />} />
        <Route path=":supplierId" element={<SupplierRoute />} />
      </Route>
    </Routes>
  )
}
