import { Controller, useFieldArray, useForm } from 'react-hook-form'
import {
  Button,
  FormLabel,
  FormControl,
  Textarea,
  FormErrorMessage,
  Select,
  IconButton,
} from '@chakra-ui/react'
import { MdOutlineDelete } from 'react-icons/md'
import { DeepPartial } from '@/types'
import { Row, Rows } from '@/components/rows'
import { ClientSelect } from '@/components/client-select'
import { ProjectSelect } from '@/components/project-select'
import { ProjectControlSelect } from '@/components/project-control-select'
import { Column, Columns } from '@/components/columns'
import { Table, Thead, Tbody, Tr, Th, Td } from '@/components/table'
import { InvoiceProductSelect } from '@/components/invoice-product-select'
import { Input } from '@/components/input'
import { AddressFormFields } from '@/components/address-form-fields'
import { ActionsBar } from '@/components/actions-bar'
import { NumberInput } from '@/components/number-input'

type Props = {
  onSubmit: (input: FormValues) => Promise<void> | void
  defaultValues?: DeepPartial<FormValues>
}

export type FormValues = {
  clientId: string
  projectId: string | null
  projectControlId: string | null
  type: string
  sequenceNumber: number
  invoiceVariant: string
  invoiceYear: number
  invoiceNumber: number
  issueDate: string
  dueDate: string
  description: string | null
  recipient: {
    name: string
    contactPerson: string
    email: string
    address: {
      streetAddress: string
      postalCode: string
      postalArea: string
    }
  }
  items: {
    id?: string
    productId: string
    description: string
    unitPrice: number
    quantity: number
    vatRate: number
  }[]
}

export function EditInvoiceForm({ defaultValues, onSubmit }: Props) {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
  })
  const { fields, append, remove } = useFieldArray({
    keyName: '_id',
    control,
    name: 'items',
  })

  const [clientId, projectId] = watch(['clientId', 'projectId'])

  return (
    <form id="invoice-form" onSubmit={handleSubmit(onSubmit)}>
      <Rows>
        <Row>
          <Columns gap={32}>
            <Column>
              <Rows>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>Dato</FormLabel>
                    <Input
                      type="date"
                      {...register('issueDate', { required: true })}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>Forfall</FormLabel>
                    <Input
                      type="date"
                      {...register('dueDate', { required: true })}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>Kunde</FormLabel>
                    <Controller
                      control={control}
                      name="clientId"
                      rules={{ required: true }}
                      render={({ field: { value, onChange } }) => (
                        <ClientSelect
                          prompt="Velg kunde"
                          value={value}
                          onChange={(v) => onChange(v ?? '')}
                        />
                      )}
                    />
                  </FormControl>
                </Row>
                {!!clientId && (
                  <Row>
                    <FormControl>
                      <FormLabel>Prosjekt</FormLabel>
                      <Controller
                        control={control}
                        name="projectId"
                        render={({ field: { value, onChange } }) => (
                          <ProjectSelect
                            clientId={clientId}
                            prompt="Velg prosjekt"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </FormControl>
                  </Row>
                )}
                {!!projectId && (
                  <Row>
                    <FormControl>
                      <FormLabel>Etterkontroll</FormLabel>
                      <Controller
                        control={control}
                        name="projectControlId"
                        render={({ field: { value, onChange } }) => (
                          <ProjectControlSelect
                            projectId={projectId}
                            prompt="Velg etterkontroll"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </FormControl>
                  </Row>
                )}
              </Rows>
            </Column>
            <Column>
              <Rows>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>Sekvensnummer</FormLabel>
                    <Input
                      type="number"
                      {...register('sequenceNumber', {
                        required: true,
                        valueAsNumber: true,
                      })}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>Mottaker</FormLabel>
                    <Input
                      {...register('recipient.name', { required: true })}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>Kontaktperson</FormLabel>
                    <Input
                      {...register('recipient.contactPerson', {
                        required: true,
                      })}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <AddressFormFields
                    isRequired
                    prefix="recipient.address"
                    register={register}
                  />
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>E-post</FormLabel>
                    <Input {...register('recipient.email')} />
                  </FormControl>
                </Row>
              </Rows>
            </Column>
          </Columns>
        </Row>
        <Row>
          <FormControl>
            <FormLabel>Beskrivelse</FormLabel>
            <Textarea rows={9} {...register('description')} />
          </FormControl>
        </Row>
        <Row>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th style={{ width: 160 }}>Vare</Th>
                <Th>Beskrivelse</Th>
                <Th style={{ width: 140 }}>Enhetspris</Th>
                <Th style={{ width: 80 }}>Antall</Th>
                <Th style={{ width: 100 }}>MVA</Th>
                <Th style={{ width: 40 }}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {fields.map((field, i) => (
                <Tr key={field._id}>
                  <Td>
                    <FormControl>
                      <Controller
                        control={control}
                        name={`items.${i}.productId`}
                        render={({ field: { value, onChange } }) => (
                          <InvoiceProductSelect
                            value={value}
                            onChange={(v) => onChange(v ?? '')}
                          />
                        )}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl isRequired isInvalid={!!errors.items?.[i]}>
                      <Textarea
                        rows={2}
                        {...register(`items.${i}.description`, {
                          required: true,
                        })}
                      />
                      {!!errors.items?.[i]?.description?.message && (
                        <FormErrorMessage>Påkrevd</FormErrorMessage>
                      )}
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl isRequired isInvalid={!!errors.items?.[i]}>
                      <Controller
                        control={control}
                        name={`items.${i}.unitPrice`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <NumberInput
                            precision={2}
                            value={field.value}
                            onChange={(v) => field.onChange(v ?? 0)}
                          />
                        )}
                      />
                      {!!errors.items?.[i]?.unitPrice?.message && (
                        <FormErrorMessage>Påkrevd</FormErrorMessage>
                      )}
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl isRequired isInvalid={!!errors.items?.[i]}>
                      <Input
                        {...register(`items.${i}.quantity`, {
                          required: true,
                          valueAsNumber: true,
                        })}
                      />
                      {!!errors.items?.[i]?.quantity?.message && (
                        <FormErrorMessage>Påkrevd</FormErrorMessage>
                      )}
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl isRequired isInvalid={!!errors.items?.[i]}>
                      <Select
                        {...register(`items.${i}.vatRate`, {
                          required: true,
                          valueAsNumber: true,
                        })}
                      >
                        <option value="0">0 %</option>
                        <option value="0.1">10 %</option>
                        <option value="0.15">15 %</option>
                        <option value="0.25">25 %</option>
                      </Select>
                      {!!errors.items?.[i]?.quantity?.message && (
                        <FormErrorMessage>Påkrevd</FormErrorMessage>
                      )}
                    </FormControl>
                  </Td>
                  <Td>
                    <IconButton
                      aria-label="Slett"
                      icon={<MdOutlineDelete />}
                      onClick={() => remove(i)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Row>
        <Row>
          <ActionsBar center>
            <Button
              size="sm"
              variant="outline"
              onClick={() =>
                append({
                  productId: '',
                  description: '',
                  unitPrice: 0,
                  quantity: 1,
                  vatRate: 0.25,
                })
              }
            >
              Legg til varelinje +
            </Button>
          </ActionsBar>
        </Row>
      </Rows>
    </form>
  )
}
