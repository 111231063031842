import { useRef, useState } from 'react'
import { Link as RouteLink, Outlet } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  IconButton,
  Link,
  useToast,
} from '@chakra-ui/react'
import { MdOutlineDelete } from 'react-icons/md'
import { graphql } from '@/__generated__/gql'
import { usePagination } from '@/hooks/use-pagination'
import { formatDate } from '@/utils/format'
import { FullLayout } from '@/components/full-layout'
import { ContainerBox } from '@/components/container-box'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/components/table'
import { Row, Rows } from '@/components/rows'
import { Heading } from '@/components/heading'
import { LoadingSection } from '@/components/loading-section'
import { documentStages } from './components/document-form'

export const documentsDocument = graphql(`
  query Documents($input: ConnectionInputArgs!) {
    documents(input: $input) {
      totalCount
      results {
        id
        name
        url
        stage
        createdAt
        canEdit
        category {
          id
          name
        }
      }
    }
  }
`)

const deleteDocumentDocument = graphql(`
  mutation DeleteDocument($id: ID!) {
    deleteDocument(id: $id) {
      message
    }
  }
`)

export function DocumentsRoute() {
  const toast = useToast()
  const { Pagination, variables } = usePagination()
  const cancelRef = useRef<HTMLButtonElement>(null)
  const [docToDelete, setDocToDelete] = useState<string | null>(null)
  const { data, loading, error } = useQuery(documentsDocument, {
    variables: { input: variables },
  })
  const [deleteDocument, { loading: isDeleting }] = useMutation(
    deleteDocumentDocument,
    {
      refetchQueries: ['Documents'],
      onCompleted(data) {
        toast({
          status: 'success',
          title: data.deleteDocument.message,
          isClosable: true,
        })
        setDocToDelete(null)
      },
      onError(error) {
        toast({
          status: 'error',
          title: error.message,
          isClosable: true,
        })
      },
    }
  )

  const documents = data?.documents.results || []
  const totalCount = data?.documents.totalCount || 0

  if (error) throw error

  return (
    <FullLayout>
      <ContainerBox>
        <Rows>
          <Row>
            <Heading
              title="Dokumenter"
              actions={
                <Button as={RouteLink} to="new" size="sm" colorScheme="blue">
                  Nytt dokument +
                </Button>
              }
            />
          </Row>
          <Row>
            <Table>
              <Thead>
                <Tr>
                  <Th>Dokument</Th>
                  <Th>Prosesstrinn</Th>
                  <Th>Kategori</Th>
                  <Th>Dato</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {documents.map((document) => (
                  <Tr key={document.id}>
                    <Td>
                      <Link
                        href={document.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {document.name}
                      </Link>
                    </Td>
                    <Td>
                      {document.stage ? documentStages[document.stage] : '-'}
                    </Td>
                    <Td>{document.category?.name || '-'}</Td>
                    <Td>{formatDate(document.createdAt)}</Td>
                    <Td>
                      {document.canEdit && (
                        <IconButton
                          aria-label="Slett"
                          size="sm"
                          icon={<MdOutlineDelete />}
                          onClick={() => setDocToDelete(document.id)}
                        />
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {loading && <LoadingSection />}
          </Row>
          {totalCount > variables.limit && (
            <Row>
              <Pagination total={totalCount} />
            </Row>
          )}
        </Rows>
      </ContainerBox>

      <AlertDialog
        isOpen={!!docToDelete}
        leastDestructiveRef={cancelRef}
        onClose={() => setDocToDelete(null)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slett dokument
            </AlertDialogHeader>

            <AlertDialogBody>
              Er du sikker på at du ønsker å slette dette dokumentet?
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonGroup>
                <Button ref={cancelRef} onClick={() => setDocToDelete(null)}>
                  Avbryt
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() =>
                    deleteDocument({ variables: { id: docToDelete! } })
                  }
                  isLoading={isDeleting}
                >
                  Slett
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Outlet />
    </FullLayout>
  )
}
