import { forwardRef, Input, InputProps } from '@chakra-ui/react'

type Props = InputProps

const CustomInput = forwardRef(
  ({ backgroundColor = 'white', ...rest }: Props, ref) => {
    return <Input ref={ref} backgroundColor={backgroundColor} {...rest} />
  }
)

export type { InputProps }
export { CustomInput as Input }
