import { Link as RouteLink, Outlet, useParams } from 'react-router-dom'
import { Button, Link } from '@chakra-ui/react'
import { z } from 'zod'
import { useQuery } from '@apollo/client'
import { graphql } from '@/__generated__/gql'
import { ContainerBox } from '@/components/container-box'
import { FullLayout } from '@/components/full-layout'
import { Heading } from '@/components/heading'
import { Row, Rows } from '@/components/rows'
import { LoadingSection } from '@/components/loading-section'
import { ProjectsList } from './components/projects-list'

export const projectsDocument = graphql(`
  query ClientProjects($id: ID!) {
    client(id: $id) {
      id
      name
      ...ProjectsListProjects
    }
  }
`)

export function ProjectsRoute() {
  const params = z.object({ clientId: z.string() }).parse(useParams())
  const { data, loading, error } = useQuery(projectsDocument, {
    variables: { id: params.clientId },
  })
  if (error) throw error

  const projects = data?.client?.projects ?? []

  return (
    <FullLayout>
      <ContainerBox>
        <Rows>
          <Row>
            <Heading
              title={
                <>
                  <Link as={RouteLink} to={`../${params.clientId}`}>
                    {data?.client.name}
                  </Link>{' '}
                  / Prosjekter
                </>
              }
              actions={
                <Button as={RouteLink} to="new" colorScheme="blue" size="sm">
                  Nytt prosjekt +
                </Button>
              }
            />
          </Row>
          <Row>
            {loading && <LoadingSection />}
            <ProjectsList projects={projects} />
          </Row>
        </Rows>
      </ContainerBox>

      <Outlet />
    </FullLayout>
  )
}
