import { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { forwardRef, Select, SelectProps } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'

type Props = {
  prompt?: string
  value: string | null
  onChange: (value: string | null) => void
} & Omit<SelectProps, 'value' | 'onChange'>

export const suppliersDocument = graphql(`
  query SupplierSelect {
    suppliers(input: { limit: 999 }) {
      results {
        id
        name
      }
    }
  }
`)

export const SupplierSelect = forwardRef(
  ({ prompt, value, onChange, ...rest }: Props, ref) => {
    const { data, error } = useQuery(suppliersDocument)
    const suppliers = data?.suppliers.results || []

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        onChange?.(e.target.value || null)
      },
      [onChange]
    )

    if (error) throw error

    return (
      <Select ref={ref} value={value ?? ''} onChange={handleChange} {...rest}>
        {prompt && <option>{prompt}</option>}
        {suppliers.map((supplier) => (
          <option key={supplier.id} value={supplier.id}>
            {supplier.name}
          </option>
        ))}
      </Select>
    )
  }
)
