import { useCallback } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { useQuery } from '@apollo/client'
import { Button, ButtonGroup } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { formatDateTime, formatPhoneNumber } from '@/utils/format'
import { Drawer } from '@/components/drawer'
import { Row, Rows } from '@/components/rows'
import { Column, Columns } from '@/components/columns'
import { LabelledContent } from '@/components/labelled-content'
import { ActionsBar } from '@/components/actions-bar'
import { LoadingSection } from '@/components/loading-section'

const clientDocument = graphql(`
  query Client($id: ID!) {
    client(id: $id) {
      id
      publicRef
      status
      name
      organizationNumber
      email
      phone
      comment
      employeesCount
      employeesYear
      revenuesYear
      revenuesAmount
      businessSector
      createdAt
      updatedAt
      canEdit
      user {
        id
        name
      }
      address {
        streetAddress
        postalCode
        postalArea
      }
      postAddress {
        streetAddress
        postalCode
        postalArea
      }
      contacts {
        name
        position
        email
        phone
      }
    }
  }
`)

export function ClientRoute() {
  const params = z.object({ clientId: z.string() }).parse(useParams())
  const navigate = useNavigate()
  const onClose = useCallback(() => navigate('..'), [navigate])

  const { data, loading, error } = useQuery(clientDocument, {
    variables: { id: params.clientId },
  })

  if (error) throw error

  const client = data?.client

  return (
    <Drawer size="lg" title={client?.name || ''} onClose={onClose}>
      {loading && <LoadingSection />}
      {!!client && (
        <Rows>
          <Row>
            <ActionsBar>
              <ButtonGroup size="sm">
                {client.canEdit && (
                  <Button as={Link} to="./edit">
                    Endre
                  </Button>
                )}
                <Button as={Link} to="./projects">
                  Prosjekter
                </Button>
              </ButtonGroup>
            </ActionsBar>
          </Row>
          <Row>
            <Columns gap={32}>
              <Column>
                <Rows>
                  <Row>
                    <LabelledContent label="Kundenummer">
                      {client.publicRef}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Status">
                      {client.status}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Org.nr.">
                      {client.organizationNumber}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Telefon">
                      {client.phone ? formatPhoneNumber(client.phone) : '-'}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Email">
                      {client.email || '-'}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Adresse">
                      {client.address ? (
                        <>
                          {client.address.streetAddress},{' '}
                          {client.address.postalCode}{' '}
                          {client.address.postalArea}
                        </>
                      ) : (
                        '-'
                      )}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Postadresse">
                      {client.postAddress ? (
                        <>
                          {client.postAddress.streetAddress},{' '}
                          {client.postAddress.postalCode}{' '}
                          {client.postAddress.postalArea}
                        </>
                      ) : (
                        '-'
                      )}
                    </LabelledContent>
                  </Row>
                </Rows>
              </Column>

              <Column>
                <Rows>
                  <Row>
                    <LabelledContent label="Rådgiver">
                      {client.user?.name || '-'}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Omsetning">
                      {client.revenuesAmount ? (
                        <>
                          {client.revenuesAmount}
                          {client.revenuesYear && <>({client.revenuesYear})</>}
                        </>
                      ) : (
                        '-'
                      )}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Ansatte">
                      {client.employeesCount ? (
                        <>
                          {client.employeesCount}
                          {client.employeesYear && (
                            <>({client.employeesYear})</>
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Bransje">
                      {client.businessSector || '-'}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Registreringsdato">
                      {formatDateTime(client.createdAt)}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Sist oppdatert">
                      {formatDateTime(client.updatedAt)}
                    </LabelledContent>
                  </Row>
                  <Row>
                    <LabelledContent label="Kommentar">
                      {client.comment || '-'}
                    </LabelledContent>
                  </Row>
                </Rows>
              </Column>
            </Columns>
          </Row>
          <Row>
            <LabelledContent label="Kontaktpersoner">
              <Rows>
                {client.contacts.map((contact, i) => (
                  <Row key={i}>
                    <div>
                      {contact.name}
                      {contact.position ? ` (${contact.position})` : ''}
                    </div>
                    {contact.email && <div>{contact.email}</div>}
                    {contact.phone && (
                      <div>{formatPhoneNumber(contact.phone)}</div>
                    )}
                  </Row>
                ))}
              </Rows>
            </LabelledContent>
          </Row>
        </Rows>
      )}
    </Drawer>
  )
}
