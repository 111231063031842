import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { z } from 'zod'
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { Drawer } from '@/components/drawer'
import { LoadingSection } from '@/components/loading-section'
import {
  ProjectControlForm,
  FormValues,
} from './components/project-control-form'

export const projectControlFragmentDocument = graphql(`
  fragment ProjectControlFragment on ProjectControl {
    id
    sequenceNumber
    fromDate
    toDate
    feeInPercentage
    savings
  }
`)

const editProjectControlDocument = graphql(`
  query EditProjectControl($id: ID!) {
    projectControl(id: $id) {
      ...ProjectControlFragment
    }
  }
`)

const updateProjectControlDocument = graphql(`
  mutation UpdateProjectControl($id: ID!, $input: ProjectControlInput!) {
    updateProjectControl(id: $id, input: $input) {
      message
      projectControl {
        ...ProjectControlFragment
      }
    }
  }
`)

export function EditProjectControlRoute() {
  const params = z.object({ projectControlId: z.string() }).parse(useParams())
  const navigate = useNavigate()
  const toast = useToast()
  const onClose = useCallback(() => navigate('..'), [navigate])
  const { data, loading, error } = useQuery(editProjectControlDocument, {
    variables: { id: params.projectControlId },
  })
  const [updateProjectControl, { loading: isUpdating }] = useMutation(
    updateProjectControlDocument,
    {
      onCompleted(data) {
        toast({
          status: 'success',
          title: data.updateProjectControl.message,
          isClosable: true,
        })
        onClose()
      },
      onError(error) {
        toast({
          status: 'error',
          title: error.message,
          isClosable: true,
        })
      },
    }
  )

  const onSubmit = async (input: FormValues) => {
    await updateProjectControl({
      variables: { id: params.projectControlId, input },
    })
  }

  if (error) throw error

  const control = data?.projectControl

  return (
    <Drawer
      title={`Endre etterkontroll ${control?.sequenceNumber || ''}`}
      onClose={onClose}
      footer={
        <ButtonGroup>
          <Button onClick={onClose}>Avbryt</Button>
          <Button
            form="project-control-form"
            type="submit"
            colorScheme="blue"
            isLoading={isUpdating}
          >
            Lagre etterkontroll
          </Button>
        </ButtonGroup>
      }
    >
      {loading && <LoadingSection />}
      {!!control && (
        <ProjectControlForm
          onSubmit={onSubmit}
          defaultValues={{
            feeInPercentage: control.feeInPercentage,
            savings: control.savings,
            fromDate: control.fromDate,
            toDate: control.toDate,
          }}
        />
      )}
    </Drawer>
  )
}
