import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { useMutation } from '@apollo/client'
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import { addDays, format } from 'date-fns'
import { graphql } from '@/__generated__/gql'
import { Drawer } from '@/components/drawer'
import {
  CollectionNoticeForm,
  FormValues,
} from './components/collection-notice-form'

const createInvoiceNoticeDocument = graphql(`
  mutation CreateInvoiceNotice($input: InvoiceNoticeInput!) {
    createInvoiceNotice(input: $input) {
      message
      invoiceNotice {
        id
      }
    }
  }
`)

export function CollectionNoticeInvoiceRoute() {
  const params = z.object({ invoiceId: z.string() }).parse(useParams())
  const navigate = useNavigate()
  const toast = useToast()
  const onClose = useCallback(() => navigate('..'), [navigate])

  const [createInvoiceNotice, { loading: isSubmitting }] = useMutation(
    createInvoiceNoticeDocument,
    {
      onCompleted(data) {
        toast({
          status: 'success',
          title: data.createInvoiceNotice.message,
          isClosable: true,
        })
        navigate(`../${params.invoiceId}`)
      },
      onError(error) {
        toast({
          status: 'error',
          title: error.message,
          isClosable: true,
        })
      },
    }
  )

  const onSubmit = useCallback(
    async (input: FormValues) => {
      await createInvoiceNotice({
        variables: {
          input: { invoiceId: params.invoiceId, ...input },
        },
      })
    },
    [createInvoiceNotice, params]
  )

  return (
    <Drawer
      title="Ny inkassovarsel"
      onClose={onClose}
      footer={
        <ButtonGroup>
          <Button onClick={() => navigate(`../${params.invoiceId}`)}>
            Avbryt
          </Button>
          <Button
            form="invoice-form"
            type="submit"
            colorScheme="blue"
            isLoading={isSubmitting}
          >
            Lag inkassovarsel
          </Button>
        </ButtonGroup>
      }
    >
      <CollectionNoticeForm
        onSubmit={onSubmit}
        defaultValues={{
          issueDate: format(new Date(), 'Y-MM-dd'),
          dueDate: format(addDays(new Date(), 14), 'Y-MM-dd'),
          fee: 210,
        }}
      />
    </Drawer>
  )
}
