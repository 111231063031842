import { Route, Routes } from 'react-router-dom'
import { EditUserRoute } from './edit'
import { NewUserRoute } from './new'
import { UserRoute } from './user'
import { UsersRoute } from './users'

export function UsersRoutes() {
  return (
    <Routes>
      <Route path="/" element={<UsersRoute />}>
        <Route path="new" element={<NewUserRoute />} />
        <Route path=":userId" element={<UserRoute />} />
        <Route path=":userId/edit" element={<EditUserRoute />} />
      </Route>
    </Routes>
  )
}
