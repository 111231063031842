import { Link } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { ProjectsListProjectsFragment } from '@/__generated__/gql/graphql'
import { formatDate, formatNumber } from '@/utils/format'
import './index.scss'

type Props = {
  projects: ProjectsListProjectsFragment['projects']
}

export const projectsFragmentDocument = graphql(`
  fragment ProjectsListProjects on Client {
    projects {
      id
      category {
        id
        name
      }
      publicRef
      invoicePolicy
      feeInPercentage
      date
      currentSupplierName
      estimatedBenchmark
      analysisMeetingDate
      reportDate
      reportApprovedDate
      reportBenchmark
      reportSavings
      reportSavingsPercentage
      newSupplierName
      newSupplierStartDate
      agreementUrl
      approvedReportUrl
      controls {
        id
        sequenceNumber
      }
    }
  }
`)

export function ProjectsList({ projects }: Props) {
  return (
    <div className="projects-list">
      <div className="projects-list--aside">
        <div className="projects-list--item strong">Prosjektnummer</div>
        <div className="projects-list--item strong">Kostnadskategori</div>
        <div className="projects-list--item strong">BM estimat (kr pr. år)</div>
        <div className="projects-list--item strong">Honorar i %</div>
        <div className="projects-list--item strong">Samarbeidsavtale dato</div>
        <div className="projects-list--item strong">Dagens leverandør</div>
        <div className="projects-list--item">Analysemøte dato</div>
        <div className="projects-list--item">Resultatrapport pres. dato</div>
        <div className="projects-list--item">Resultatrapport godkj. dato</div>
        <div className="projects-list--item">BM i kr (actual) fra rapport</div>
        <div className="projects-list--item">Besparelse i kr fra rapport</div>
        <div className="projects-list--item">Besparelse i % fra rapport</div>
        <div className="projects-list--item">Leverandørvalg</div>
        <div className="projects-list--item">Oppstartsdato ny leverandør</div>
        <div className="projects-list--item">Faktureringsmodell</div>
        <div className="projects-list--item">Samarbeidsavtale</div>
        <div className="projects-list--item">Resultatrapport</div>
      </div>

      <div className="projects-list--projects-container">
        {projects.map((project) => (
          <div key={project.id} className="projects-list--project">
            <div className="projects-list--item">{project.publicRef}</div>
            <div className="projects-list--item">{project.category.name}</div>
            <div className="projects-list--item">
              {project.estimatedBenchmark
                ? formatNumber(project.estimatedBenchmark, 0)
                : '-'}
            </div>
            <div className="projects-list--item">{project.feeInPercentage}</div>
            <div className="projects-list--item">
              {project.date ? formatDate(project.date) : '-'}
            </div>
            <div className="projects-list--item">
              {project.currentSupplierName}
            </div>
            <div className="projects-list--item">
              {project.analysisMeetingDate
                ? formatDate(project.analysisMeetingDate)
                : '-'}
            </div>
            <div className="projects-list--item">
              {project.reportDate ? formatDate(project.reportDate) : '-'}
            </div>
            <div className="projects-list--item">
              {project.reportApprovedDate
                ? formatDate(project.reportApprovedDate)
                : '-'}
            </div>
            <div className="projects-list--item">
              {project.reportBenchmark
                ? formatNumber(project.reportBenchmark, 0)
                : '-'}
            </div>
            <div className="projects-list--item">
              {project.reportSavings
                ? formatNumber(project.reportSavings, 0)
                : '-'}
            </div>
            <div className="projects-list--item">
              {project.reportSavingsPercentage}
            </div>
            <div className="projects-list--item">{project.newSupplierName}</div>
            <div className="projects-list--item">
              {project.newSupplierStartDate
                ? formatDate(project.newSupplierStartDate)
                : '-'}
            </div>
            <div className="projects-list--item">{project.invoicePolicy}</div>
            <div className="projects-list--item">
              {project.agreementUrl ? (
                <a href={project.agreementUrl} target="_blank" rel="noreferrer">
                  Vis fil
                </a>
              ) : (
                '-'
              )}
            </div>
            <div className="projects-list--item">
              {project.approvedReportUrl ? (
                <a
                  href={project.approvedReportUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  Vis fil
                </a>
              ) : (
                '-'
              )}
            </div>
            <div className="projects-list--item">
              <Button
                as={Link}
                to={`${project.id}/edit`}
                size="sm"
                variant="outline"
                width="100%"
              >
                Endre
              </Button>
            </div>
            {project.controls.map((control) => (
              <div key={control.id} className="projects-list--item">
                <Button
                  as={Link}
                  to={`${project.id}/controls/${control.id}/edit`}
                  size="sm"
                  variant="outline"
                  width="100%"
                >
                  Etterkontroll {control.sequenceNumber}
                </Button>
              </div>
            ))}
            <div className="projects-list--item">
              <Button
                as={Link}
                to={`${project.id}/controls/new`}
                size="sm"
                variant="outline"
                width="100%"
              >
                Ny etterkontroll
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
