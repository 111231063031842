import { Controller, useForm } from 'react-hook-form'
import { FormLabel, FormControl, Select } from '@chakra-ui/react'
import { ProjectInvoicePolicy } from '@/__generated__/gql/graphql'
import { Row, Rows } from '@/components/rows'
import { Column, Columns } from '@/components/columns'
import { Input } from '@/components/input'
import { CategorySelect } from '@/components/category-select'
import { NumberInput } from '@/components/number-input'
import { FileUploadInput } from '@/components/file-upload-input'
import { DateInput } from '@/components/date-input'

type Props = {
  onSubmit: (input: FormValues) => Promise<void> | void
  defaultValues?: Partial<FormValues>
}

export type FormValues = {
  categoryId: string
  invoicePolicy: ProjectInvoicePolicy
  feeInPercentage: number | null
  date: string | null
  agreementUrl: string | null
  currentSupplierName: string | null
  estimatedBenchmark: number | null
  analysisMeetingDate: string | null
  reportDate: string | null
  reportApprovedDate: string | null
  approvedReportUrl: string | null
  reportBenchmark: number | null
  reportSavings: number | null
  reportSavingsPercentage: number | null
  newSupplierName: string | null
  newSupplierStartDate: string | null
}

export function ProjectForm({ defaultValues, onSubmit }: Props) {
  const { register, control, handleSubmit } = useForm<FormValues>({
    defaultValues,
  })

  return (
    <form id="project-form" onSubmit={handleSubmit(onSubmit)}>
      <Rows>
        <Row>
          <Columns gap={32}>
            <Column>
              <Rows>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>Kategori</FormLabel>
                    <Controller
                      control={control}
                      name="categoryId"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CategorySelect
                          prompt="Velg kategori"
                          value={field.value}
                          onChange={(v) => field.onChange(v ?? '')}
                        />
                      )}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>Honorar i %</FormLabel>
                    <Controller
                      control={control}
                      name="feeInPercentage"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <NumberInput
                          precision={2}
                          max={100}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>Samarbeidsavtale dato</FormLabel>
                    <Controller
                      control={control}
                      name="date"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <DateInput
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>Dagens leverandør</FormLabel>
                    <Input
                      {...register('currentSupplierName', { required: true })}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>BM estimat (kr pr. år)</FormLabel>
                    <Controller
                      control={control}
                      name="estimatedBenchmark"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <NumberInput
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>Faktureringsmodell</FormLabel>
                    <Select {...register('invoicePolicy', { required: true })}>
                      <option value={ProjectInvoicePolicy.Default}>
                        Standard (4 etterkontroller)
                      </option>
                      <option value={ProjectInvoicePolicy.Custom}>
                        Egendefinert (4 faktura)
                      </option>
                    </Select>
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Samarbeidsavtale</FormLabel>
                    <Controller
                      control={control}
                      name="agreementUrl"
                      render={({ field }) => (
                        <FileUploadInput
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Resultatrapport</FormLabel>
                    <Controller
                      control={control}
                      name="approvedReportUrl"
                      render={({ field }) => (
                        <FileUploadInput
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                </Row>
              </Rows>
            </Column>
            <Column>
              <Rows>
                <Row>
                  <FormControl>
                    <FormLabel>Analysemøte dato</FormLabel>
                    <Controller
                      control={control}
                      name="analysisMeetingDate"
                      render={({ field }) => (
                        <DateInput
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Resultatrapport pres. dato</FormLabel>
                    <Controller
                      control={control}
                      name="reportDate"
                      render={({ field }) => (
                        <DateInput
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Resultatrapport godkj. dato</FormLabel>
                    <Controller
                      control={control}
                      name="reportApprovedDate"
                      render={({ field }) => (
                        <DateInput
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>BM i kr (actual) fra rapport</FormLabel>
                    <Controller
                      control={control}
                      name="reportBenchmark"
                      render={({ field }) => (
                        <NumberInput
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Besparelse i kr fra rapport</FormLabel>
                    <Controller
                      control={control}
                      name="reportSavings"
                      render={({ field }) => (
                        <NumberInput
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Besparelse i % fra rapport</FormLabel>
                    <Controller
                      control={control}
                      name="reportSavingsPercentage"
                      render={({ field }) => (
                        <NumberInput
                          precision={1}
                          max={100}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Leverandørvalg</FormLabel>
                    <Input {...register('newSupplierName')} />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Oppstartsdato ny leverandør</FormLabel>
                    <Controller
                      control={control}
                      name="newSupplierStartDate"
                      render={({ field }) => (
                        <DateInput
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                </Row>
              </Rows>
            </Column>
          </Columns>
        </Row>
      </Rows>
    </form>
  )
}
