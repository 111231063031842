import { useCallback } from 'react'
import type { PaginationProps } from 'rc-pagination'
import { Pagination } from '@/components/pagination'
import { useQueryParams } from './use-query-params'

const DEFAULT_LIMIT = 30

interface Props {
  limit?: number
}

export function usePagination({ limit = DEFAULT_LIMIT }: Props = {}) {
  const [queryParams, setQueryParams] = useQueryParams()

  const variables = {
    limit,
    page: Number(queryParams.page || 1),
  }

  const setPage = useCallback(
    (page: number) => {
      setQueryParams({ page: String(page) })
    },
    [setQueryParams]
  )

  const Component = useCallback(
    (props: PaginationProps) => (
      <Pagination
        pageSize={variables.limit}
        current={variables.page}
        onChange={setPage}
        {...props}
      />
    ),
    [setPage, variables]
  )

  return {
    Pagination: Component,
    variables,
  }
}
