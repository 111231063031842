import { Link as RouteLink, Outlet } from 'react-router-dom'
import { Button, Link } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { graphql } from '@/__generated__/gql'
import { usePagination } from '@/hooks/use-pagination'
import { formatPhoneNumber } from '@/utils/format'
import { FullLayout } from '@/components/full-layout'
import { ContainerBox } from '@/components/container-box'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/components/table'
import { Row, Rows } from '@/components/rows'
import { Heading } from '@/components/heading'
import { useViewer } from '@/components/viewer-context-provider'
import { LoadingSection } from '@/components/loading-section'

export const usersDocument = graphql(`
  query Users($input: ConnectionInputArgs!) {
    users(input: $input) {
      totalCount
      results {
        id
        name
        email
        mobile
        address {
          streetAddress
          postalArea
        }
      }
    }
  }
`)

export function UsersRoute() {
  const { isAdmin } = useViewer()
  const { Pagination, variables } = usePagination()
  const { data, loading, error } = useQuery(usersDocument, {
    variables: { input: variables },
  })
  const users = data?.users.results || []
  const totalCount = data?.users.totalCount || 0

  if (error) throw error

  return (
    <FullLayout>
      <ContainerBox>
        <Rows>
          <Row>
            <Heading
              title="Brukere"
              actions={
                isAdmin ? (
                  <Button as={RouteLink} to="new" size="sm" colorScheme="blue">
                    Ny bruker +
                  </Button>
                ) : null
              }
            />
          </Row>
          <Row>
            <Table>
              <Thead>
                <Tr>
                  <Th>Navn</Th>
                  <Th>Adresse</Th>
                  <Th>Poststed</Th>
                  <Th>Mobil</Th>
                  <Th>E-post</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.map((user) => (
                  <Tr key={user.id}>
                    <Td>
                      <Link as={RouteLink} to={user.id}>
                        {user.name}
                      </Link>
                    </Td>
                    <Td>{user.address.streetAddress}</Td>
                    <Td>{user.address.postalArea}</Td>
                    <Td>{formatPhoneNumber(user.mobile)}</Td>
                    <Td>{user.email}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {loading && <LoadingSection />}
          </Row>
          {totalCount > variables.limit && (
            <Row>
              <Pagination total={totalCount} />
            </Row>
          )}
        </Rows>
      </ContainerBox>

      <Outlet />
    </FullLayout>
  )
}
