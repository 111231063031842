/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query CategoriesSelect {\n    categories(input: { parentId: null }) {\n      results {\n        id\n        name\n        children {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.CategoriesSelectDocument,
    "\n  query CategorySelect {\n    categories(input: { parentId: null }) {\n      results {\n        id\n        name\n        children {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.CategorySelectDocument,
    "\n  query ClientSelect {\n    clients(input: { limit: 999 }) {\n      results {\n        id\n        name\n      }\n    }\n  }\n": types.ClientSelectDocument,
    "\n  query SignedUploadUrl($input: FileUploadInput!) {\n    signedUploadUrl(input: $input)\n  }\n": types.SignedUploadUrlDocument,
    "\n  query InvoiceProductSelect {\n    invoiceProducts {\n      results {\n        id\n        name\n      }\n    }\n  }\n": types.InvoiceProductSelectDocument,
    "\n  query ProjectControlSelect($projectId: ID!) {\n    project(id: $projectId) {\n      id\n      controls {\n        id\n        publicRef\n      }\n    }\n  }\n": types.ProjectControlSelectDocument,
    "\n  query ProjectSelect($input: ProjectsConnectionInputArgs!) {\n    projects(input: $input) {\n      results {\n        id\n        publicRef\n        category {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.ProjectSelectDocument,
    "\n  query SupplierSelect {\n    suppliers(input: { limit: 999 }) {\n      results {\n        id\n        name\n      }\n    }\n  }\n": types.SupplierSelectDocument,
    "\n  query UserSelect {\n    users {\n      results {\n        id\n        name\n      }\n    }\n  }\n": types.UserSelectDocument,
    "\n  query ViewerContextProvider {\n    viewer {\n      id\n      instance {\n        id\n        name\n      }\n      user {\n        id\n        name\n        instances {\n          id\n          name\n        }\n      }\n      roles\n    }\n  }\n": types.ViewerContextProviderDocument,
    "\n  query Categories {\n    categories(input: { parentId: null }) {\n      results {\n        id\n        name\n        children {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.CategoriesDocument,
    "\n  query Client($id: ID!) {\n    client(id: $id) {\n      id\n      publicRef\n      status\n      name\n      organizationNumber\n      email\n      phone\n      comment\n      employeesCount\n      employeesYear\n      revenuesYear\n      revenuesAmount\n      businessSector\n      createdAt\n      updatedAt\n      canEdit\n      user {\n        id\n        name\n      }\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n      postAddress {\n        streetAddress\n        postalCode\n        postalArea\n      }\n      contacts {\n        name\n        position\n        email\n        phone\n      }\n    }\n  }\n": types.ClientDocument,
    "\n  query Clients($input: ConnectionInputArgs!) {\n    clients(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        publicRef\n        status\n        address {\n          postalArea\n        }\n        user {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.ClientsDocument,
    "\n  query CompanyLookup($organizationNumber: String!) {\n    companyLookupFromSource(organizationNumber: $organizationNumber) {\n      id\n      name\n      organizationNumber\n      phone\n      email\n      employeesCount\n      employeesYear\n      revenuesYear\n      revenuesAmount\n      businessSector\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n    }\n  }\n": types.CompanyLookupDocument,
    "\n  fragment ProjectsListProjects on Client {\n    projects {\n      id\n      category {\n        id\n        name\n      }\n      publicRef\n      invoicePolicy\n      feeInPercentage\n      date\n      currentSupplierName\n      estimatedBenchmark\n      analysisMeetingDate\n      reportDate\n      reportApprovedDate\n      reportBenchmark\n      reportSavings\n      reportSavingsPercentage\n      newSupplierName\n      newSupplierStartDate\n      agreementUrl\n      approvedReportUrl\n      controls {\n        id\n        sequenceNumber\n      }\n    }\n  }\n": types.ProjectsListProjectsFragmentDoc,
    "\n  fragment ProjectControlFragment on ProjectControl {\n    id\n    sequenceNumber\n    fromDate\n    toDate\n    feeInPercentage\n    savings\n  }\n": types.ProjectControlFragmentFragmentDoc,
    "\n  query EditProjectControl($id: ID!) {\n    projectControl(id: $id) {\n      ...ProjectControlFragment\n    }\n  }\n": types.EditProjectControlDocument,
    "\n  mutation UpdateProjectControl($id: ID!, $input: ProjectControlInput!) {\n    updateProjectControl(id: $id, input: $input) {\n      message\n      projectControl {\n        ...ProjectControlFragment\n      }\n    }\n  }\n": types.UpdateProjectControlDocument,
    "\n  fragment ProjectFragment on Project {\n    id\n    publicRef\n    invoicePolicy\n    category { id }\n    feeInPercentage\n    date\n    currentSupplierName\n    estimatedBenchmark\n    analysisMeetingDate\n    reportDate\n    reportApprovedDate\n    reportBenchmark\n    reportSavings\n    reportSavingsPercentage\n    newSupplierName\n    newSupplierStartDate\n    agreementUrl\n    approvedReportUrl\n  }\n": types.ProjectFragmentFragmentDoc,
    "\n  query EditProject($id: ID!) {\n    project(id: $id) {\n      ...ProjectFragment\n    }\n  }\n": types.EditProjectDocument,
    "\n  mutation UpdateProject($id: ID!, $input: ProjectInput!) {\n    updateProject(id: $id, input: $input) {\n      message\n      project {\n        ...ProjectFragment\n      }\n    }\n  }\n": types.UpdateProjectDocument,
    "\n  fragment ClientItem on Client {\n    id\n    publicRef\n    status\n    name\n    organizationNumber\n    email\n    phone\n    comment\n    employeesCount\n    employeesYear\n    revenuesYear\n    revenuesAmount\n    businessSector\n    createdAt\n    updatedAt\n    user {\n      id\n    }\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    contacts {\n      name\n      position\n      email\n      phone\n    }\n  }\n": types.ClientItemFragmentDoc,
    "\n  query EditClient($id: ID!) {\n    client(id: $id) {\n      ...ClientItem\n    }\n  }\n": types.EditClientDocument,
    "\n  mutation UpdateClient($id: ID!, $input: ClientInput!) {\n    updateClient(id: $id, input: $input) {\n      message\n      client {\n        ...ClientItem\n      }\n    }\n  }\n": types.UpdateClientDocument,
    "\n  mutation CreateProjectControl($input: ProjectControlInput!) {\n    createProjectControl(input: $input) {\n      message\n      projectControl {\n        id\n      }\n    }\n  }\n": types.CreateProjectControlDocument,
    "\n  mutation CreateProject($input: ProjectInput!) {\n    createProject(input: $input) {\n      message\n      project {\n        id\n      }\n    }\n  }\n": types.CreateProjectDocument,
    "\n  mutation CreateClient($input: ClientInput!) {\n    createClient(input: $input) {\n      message\n      client {\n        id\n      }\n    }\n  }\n": types.CreateClientDocument,
    "\n  query ClientProjects($id: ID!) {\n    client(id: $id) {\n      id\n      name\n      ...ProjectsListProjects\n    }\n  }\n": types.ClientProjectsDocument,
    "\n  query DashboardProjects($input: ProjectsConnectionInputArgs!) {\n    projects(input: $input) {\n      results {\n        id\n        publicRef\n        date\n        estimatedBenchmark\n        reportBenchmark\n        reportSavings\n        client {\n          id\n          name\n          status\n          user {\n            id\n            name\n          }\n        }\n        category {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.DashboardProjectsDocument,
    "\n  query Documents($input: ConnectionInputArgs!) {\n    documents(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        url\n        stage\n        createdAt\n        canEdit\n        category {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.DocumentsDocument,
    "\n  mutation DeleteDocument($id: ID!) {\n    deleteDocument(id: $id) {\n      message\n    }\n  }\n": types.DeleteDocumentDocument,
    "\n  mutation CreateDocument($input: DocumentInput!) {\n    createDocument(input: $input) {\n      message\n      document {\n        id\n      }\n    }\n  }\n": types.CreateDocumentDocument,
    "\n  mutation CreateInvoiceNotice($input: InvoiceNoticeInput!) {\n    createInvoiceNotice(input: $input) {\n      message\n      invoiceNotice {\n        id\n      }\n    }\n  }\n": types.CreateInvoiceNoticeDocument,
    "\n  mutation CreateInvoiceCredit($input: InvoiceInput!) {\n    createInvoice(input: $input) {\n      message\n      invoice {\n        id\n      }\n    }\n  }\n": types.CreateInvoiceCreditDocument,
    "\n  query EditInvoice($id: ID!) {\n    invoice(id: $id) {\n      id\n      issueDate\n      dueDate\n      sequenceNumber\n      description\n      client {\n        id\n      }\n      project {\n        id\n      }\n      projectControl {\n        id\n      }\n      recipient {\n        name\n        contactPerson\n        email\n        address {\n          streetAddress\n          postalCode\n          postalArea\n        }\n      }\n      items {\n        results {\n          id\n          description\n          unitPrice\n          quantity\n          vatRate\n          product {\n            id\n          }\n        }\n      }\n    }\n  }\n": types.EditInvoiceDocument,
    "\n  mutation UpdateInvoice($id: ID!, $input: InvoiceInput!) {\n    updateInvoice(id: $id, input: $input) {\n      message\n      invoice {\n        id\n      }\n    }\n  }\n": types.UpdateInvoiceDocument,
    "\n  query Invoice($id: ID!) {\n    invoice(id: $id) {\n      id\n      type\n      state\n      publicRef\n      issueDate\n      dueDate\n      sum\n      vat\n      total\n      description\n      canEdit\n      client {\n        id\n        name\n      }\n      recipient {\n        contactPerson\n        email\n        address {\n          streetAddress\n          postalCode\n          postalArea\n        }\n      }\n      items {\n        results {\n          id\n          description\n          unitPrice\n          quantity\n          vat\n        }\n      }\n      credit {\n        id\n      }\n      notices {\n        id\n        type\n      }\n    }\n  }\n": types.InvoiceDocument,
    "\n  query Invoices($input: ConnectionInputArgs) {\n    invoices(input: $input) {\n      totalCount\n      results {\n        id\n        publicRef\n        type\n        issueDate\n        sum\n        total\n        client {\n          id\n          name\n        }\n        project {\n          id\n          category {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n": types.InvoicesDocument,
    "\n  mutation CreateInvoice($input: InvoiceInput!) {\n    createInvoice(input: $input) {\n      message\n      invoice {\n        id\n      }\n    }\n  }\n": types.CreateInvoiceDocument,
    "\n  mutation RequestOtp($input: OtpInput!) {\n    requestOtp(input: $input) {\n      message\n    }\n  }\n": types.RequestOtpDocument,
    "\n  mutation SignIn($input: SignInInput!) {\n    signIn(input: $input) {\n      message\n      viewer {\n        id\n        user {\n          id\n        }\n      }\n    }\n  }\n": types.SignInDocument,
    "\n  query OfferCostSizes {\n    offerCostSizes {\n      results {\n        id\n        name\n      }\n    }\n  }\n": types.OfferCostSizesDocument,
    "\n  mutation CreateOffer($input: OfferInput!) {\n    createOffer(input: $input) {\n      message\n      offer {\n        id\n      }\n    }\n  }\n": types.CreateOfferDocument,
    "\n  query Offer($id: ID!) {\n    offer(id: $id) {\n      id\n      date\n      supplier {\n        id\n        name\n      }\n      category {\n        id\n        name\n      }\n      costSize {\n        id\n        name\n      }\n      items {\n        results {\n          id\n          code\n          name\n          unit\n          unitPrice\n        }\n      }\n    }\n  }\n": types.OfferDocument,
    "\n  query Offers($input: ConnectionInputArgs!) {\n    offers(input: $input) {\n      totalCount\n      results {\n        id\n        date\n        supplier {\n          id\n          name\n        }\n        category {\n          id\n          name\n        }\n        costSize {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.OffersDocument,
    "\n  fragment EditSpecialistItem on Specialist {\n    id\n    name\n    email\n    mobile\n    companyName\n    services\n    comment\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    categories {\n      id\n      name\n    }\n  }\n": types.EditSpecialistItemFragmentDoc,
    "\n  query EditSpecialist($id: ID!) {\n    specialist(id: $id) {\n      ...EditSpecialistItem\n    }\n  }\n": types.EditSpecialistDocument,
    "\n  mutation UpdateSpecialist($id: ID!, $input: SpecialistInput!) {\n    updateSpecialist(id: $id, input: $input) {\n      message\n      specialist {\n        ...EditSpecialistItem\n      }\n    }\n  }\n": types.UpdateSpecialistDocument,
    "\n  mutation CreateSpecialist($input: SpecialistInput!) {\n    createSpecialist(input: $input) {\n      message\n      specialist {\n        id\n      }\n    }\n  }\n": types.CreateSpecialistDocument,
    "\n  query Specialist($id: ID!) {\n    specialist(id: $id) {\n      id\n      name\n      email\n      mobile\n      companyName\n      services\n      comment\n      createdAt\n      updatedAt\n      canEdit\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n      categories {\n        id\n        name\n      }\n    }\n  }\n": types.SpecialistDocument,
    "\n  query Specialists($input: ConnectionInputArgs!) {\n    specialists(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        mobile\n        email\n        categories {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.SpecialistsDocument,
    "\n  query SupplierCompanyLookup($organizationNumber: String!) {\n    companyLookupFromSource(organizationNumber: $organizationNumber) {\n      id\n      name\n      organizationNumber\n      phone\n      email\n      employeesCount\n      employeesYear\n      revenuesYear\n      revenuesAmount\n      businessSector\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n    }\n  }\n": types.SupplierCompanyLookupDocument,
    "\n  fragment SupplierItem on Supplier {\n    id\n    publicRef\n    rating\n    name\n    organizationNumber\n    email\n    phone\n    comment\n    employeesCount\n    employeesYear\n    revenuesYear\n    revenuesAmount\n    businessSector\n    createdAt\n    updatedAt\n    user {\n      id\n    }\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    contacts {\n      name\n      position\n      email\n      phone\n    }\n    categories {\n      id\n    }\n  }\n": types.SupplierItemFragmentDoc,
    "\n  query EditSupplier($id: ID!) {\n    supplier(id: $id) {\n      ...SupplierItem\n    }\n  }\n": types.EditSupplierDocument,
    "\n  mutation UpdateSupplier($id: ID!, $input: SupplierInput!) {\n    updateSupplier(id: $id, input: $input) {\n      message\n      supplier {\n        ...SupplierItem\n      }\n    }\n  }\n": types.UpdateSupplierDocument,
    "\n  mutation CreateSupplier($input: SupplierInput!) {\n    createSupplier(input: $input) {\n      message\n      supplier {\n        id\n      }\n    }\n  }\n": types.CreateSupplierDocument,
    "\n  query Supplier($id: ID!) {\n    supplier(id: $id) {\n      id\n      publicRef\n      rating\n      name\n      organizationNumber\n      email\n      phone\n      comment\n      employeesCount\n      employeesYear\n      revenuesYear\n      revenuesAmount\n      businessSector\n      createdAt\n      updatedAt\n      canEdit\n      user {\n        id\n        name\n      }\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n      postAddress {\n        streetAddress\n        postalCode\n        postalArea\n      }\n      contacts {\n        name\n        position\n        email\n        phone\n      }\n      categories {\n        id\n        name\n      }\n    }\n  }\n": types.SupplierDocument,
    "\n  query Suppliers($input: SuppliersConnectionInputArgs!) {\n    suppliers(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        publicRef\n        rating\n        address {\n          postalArea\n        }\n      }\n    }\n  }\n": types.SuppliersDocument,
    "\n  query EditUser($id: ID!) {\n    user(id: $id) {\n      id\n      name\n      email\n      mobile\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n    }\n  }\n": types.EditUserDocument,
    "\n  mutation UpdateUser($id: ID!, $input: UserInput!) {\n    updateUser(id: $id, input: $input) {\n      message\n      user {\n        id\n        name\n        email\n        mobile\n        address {\n          streetAddress\n          postalCode\n          postalArea\n        }\n      }\n    }\n  }\n": types.UpdateUserDocument,
    "\n  mutation CreateUser($input: UserInput!) {\n    createUser(input: $input) {\n      message\n      user {\n        id\n      }\n    }\n  }\n": types.CreateUserDocument,
    "\n  query User($id: ID!) {\n    user(id: $id) {\n      id\n      name\n      email\n      mobile\n      canEdit\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n    }\n  }\n": types.UserDocument,
    "\n  mutation DeactivateUser($id: ID!) {\n    deactivateUser(id: $id) {\n      message\n    }\n  }\n": types.DeactivateUserDocument,
    "\n  query Users($input: ConnectionInputArgs!) {\n    users(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        email\n        mobile\n        address {\n          streetAddress\n          postalArea\n        }\n      }\n    }\n  }\n": types.UsersDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CategoriesSelect {\n    categories(input: { parentId: null }) {\n      results {\n        id\n        name\n        children {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query CategoriesSelect {\n    categories(input: { parentId: null }) {\n      results {\n        id\n        name\n        children {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CategorySelect {\n    categories(input: { parentId: null }) {\n      results {\n        id\n        name\n        children {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query CategorySelect {\n    categories(input: { parentId: null }) {\n      results {\n        id\n        name\n        children {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ClientSelect {\n    clients(input: { limit: 999 }) {\n      results {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query ClientSelect {\n    clients(input: { limit: 999 }) {\n      results {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SignedUploadUrl($input: FileUploadInput!) {\n    signedUploadUrl(input: $input)\n  }\n"): (typeof documents)["\n  query SignedUploadUrl($input: FileUploadInput!) {\n    signedUploadUrl(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query InvoiceProductSelect {\n    invoiceProducts {\n      results {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query InvoiceProductSelect {\n    invoiceProducts {\n      results {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ProjectControlSelect($projectId: ID!) {\n    project(id: $projectId) {\n      id\n      controls {\n        id\n        publicRef\n      }\n    }\n  }\n"): (typeof documents)["\n  query ProjectControlSelect($projectId: ID!) {\n    project(id: $projectId) {\n      id\n      controls {\n        id\n        publicRef\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ProjectSelect($input: ProjectsConnectionInputArgs!) {\n    projects(input: $input) {\n      results {\n        id\n        publicRef\n        category {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ProjectSelect($input: ProjectsConnectionInputArgs!) {\n    projects(input: $input) {\n      results {\n        id\n        publicRef\n        category {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SupplierSelect {\n    suppliers(input: { limit: 999 }) {\n      results {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query SupplierSelect {\n    suppliers(input: { limit: 999 }) {\n      results {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserSelect {\n    users {\n      results {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserSelect {\n    users {\n      results {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ViewerContextProvider {\n    viewer {\n      id\n      instance {\n        id\n        name\n      }\n      user {\n        id\n        name\n        instances {\n          id\n          name\n        }\n      }\n      roles\n    }\n  }\n"): (typeof documents)["\n  query ViewerContextProvider {\n    viewer {\n      id\n      instance {\n        id\n        name\n      }\n      user {\n        id\n        name\n        instances {\n          id\n          name\n        }\n      }\n      roles\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Categories {\n    categories(input: { parentId: null }) {\n      results {\n        id\n        name\n        children {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Categories {\n    categories(input: { parentId: null }) {\n      results {\n        id\n        name\n        children {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Client($id: ID!) {\n    client(id: $id) {\n      id\n      publicRef\n      status\n      name\n      organizationNumber\n      email\n      phone\n      comment\n      employeesCount\n      employeesYear\n      revenuesYear\n      revenuesAmount\n      businessSector\n      createdAt\n      updatedAt\n      canEdit\n      user {\n        id\n        name\n      }\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n      postAddress {\n        streetAddress\n        postalCode\n        postalArea\n      }\n      contacts {\n        name\n        position\n        email\n        phone\n      }\n    }\n  }\n"): (typeof documents)["\n  query Client($id: ID!) {\n    client(id: $id) {\n      id\n      publicRef\n      status\n      name\n      organizationNumber\n      email\n      phone\n      comment\n      employeesCount\n      employeesYear\n      revenuesYear\n      revenuesAmount\n      businessSector\n      createdAt\n      updatedAt\n      canEdit\n      user {\n        id\n        name\n      }\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n      postAddress {\n        streetAddress\n        postalCode\n        postalArea\n      }\n      contacts {\n        name\n        position\n        email\n        phone\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Clients($input: ConnectionInputArgs!) {\n    clients(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        publicRef\n        status\n        address {\n          postalArea\n        }\n        user {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Clients($input: ConnectionInputArgs!) {\n    clients(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        publicRef\n        status\n        address {\n          postalArea\n        }\n        user {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CompanyLookup($organizationNumber: String!) {\n    companyLookupFromSource(organizationNumber: $organizationNumber) {\n      id\n      name\n      organizationNumber\n      phone\n      email\n      employeesCount\n      employeesYear\n      revenuesYear\n      revenuesAmount\n      businessSector\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n    }\n  }\n"): (typeof documents)["\n  query CompanyLookup($organizationNumber: String!) {\n    companyLookupFromSource(organizationNumber: $organizationNumber) {\n      id\n      name\n      organizationNumber\n      phone\n      email\n      employeesCount\n      employeesYear\n      revenuesYear\n      revenuesAmount\n      businessSector\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ProjectsListProjects on Client {\n    projects {\n      id\n      category {\n        id\n        name\n      }\n      publicRef\n      invoicePolicy\n      feeInPercentage\n      date\n      currentSupplierName\n      estimatedBenchmark\n      analysisMeetingDate\n      reportDate\n      reportApprovedDate\n      reportBenchmark\n      reportSavings\n      reportSavingsPercentage\n      newSupplierName\n      newSupplierStartDate\n      agreementUrl\n      approvedReportUrl\n      controls {\n        id\n        sequenceNumber\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment ProjectsListProjects on Client {\n    projects {\n      id\n      category {\n        id\n        name\n      }\n      publicRef\n      invoicePolicy\n      feeInPercentage\n      date\n      currentSupplierName\n      estimatedBenchmark\n      analysisMeetingDate\n      reportDate\n      reportApprovedDate\n      reportBenchmark\n      reportSavings\n      reportSavingsPercentage\n      newSupplierName\n      newSupplierStartDate\n      agreementUrl\n      approvedReportUrl\n      controls {\n        id\n        sequenceNumber\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ProjectControlFragment on ProjectControl {\n    id\n    sequenceNumber\n    fromDate\n    toDate\n    feeInPercentage\n    savings\n  }\n"): (typeof documents)["\n  fragment ProjectControlFragment on ProjectControl {\n    id\n    sequenceNumber\n    fromDate\n    toDate\n    feeInPercentage\n    savings\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query EditProjectControl($id: ID!) {\n    projectControl(id: $id) {\n      ...ProjectControlFragment\n    }\n  }\n"): (typeof documents)["\n  query EditProjectControl($id: ID!) {\n    projectControl(id: $id) {\n      ...ProjectControlFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateProjectControl($id: ID!, $input: ProjectControlInput!) {\n    updateProjectControl(id: $id, input: $input) {\n      message\n      projectControl {\n        ...ProjectControlFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateProjectControl($id: ID!, $input: ProjectControlInput!) {\n    updateProjectControl(id: $id, input: $input) {\n      message\n      projectControl {\n        ...ProjectControlFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ProjectFragment on Project {\n    id\n    publicRef\n    invoicePolicy\n    category { id }\n    feeInPercentage\n    date\n    currentSupplierName\n    estimatedBenchmark\n    analysisMeetingDate\n    reportDate\n    reportApprovedDate\n    reportBenchmark\n    reportSavings\n    reportSavingsPercentage\n    newSupplierName\n    newSupplierStartDate\n    agreementUrl\n    approvedReportUrl\n  }\n"): (typeof documents)["\n  fragment ProjectFragment on Project {\n    id\n    publicRef\n    invoicePolicy\n    category { id }\n    feeInPercentage\n    date\n    currentSupplierName\n    estimatedBenchmark\n    analysisMeetingDate\n    reportDate\n    reportApprovedDate\n    reportBenchmark\n    reportSavings\n    reportSavingsPercentage\n    newSupplierName\n    newSupplierStartDate\n    agreementUrl\n    approvedReportUrl\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query EditProject($id: ID!) {\n    project(id: $id) {\n      ...ProjectFragment\n    }\n  }\n"): (typeof documents)["\n  query EditProject($id: ID!) {\n    project(id: $id) {\n      ...ProjectFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateProject($id: ID!, $input: ProjectInput!) {\n    updateProject(id: $id, input: $input) {\n      message\n      project {\n        ...ProjectFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateProject($id: ID!, $input: ProjectInput!) {\n    updateProject(id: $id, input: $input) {\n      message\n      project {\n        ...ProjectFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ClientItem on Client {\n    id\n    publicRef\n    status\n    name\n    organizationNumber\n    email\n    phone\n    comment\n    employeesCount\n    employeesYear\n    revenuesYear\n    revenuesAmount\n    businessSector\n    createdAt\n    updatedAt\n    user {\n      id\n    }\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    contacts {\n      name\n      position\n      email\n      phone\n    }\n  }\n"): (typeof documents)["\n  fragment ClientItem on Client {\n    id\n    publicRef\n    status\n    name\n    organizationNumber\n    email\n    phone\n    comment\n    employeesCount\n    employeesYear\n    revenuesYear\n    revenuesAmount\n    businessSector\n    createdAt\n    updatedAt\n    user {\n      id\n    }\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    contacts {\n      name\n      position\n      email\n      phone\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query EditClient($id: ID!) {\n    client(id: $id) {\n      ...ClientItem\n    }\n  }\n"): (typeof documents)["\n  query EditClient($id: ID!) {\n    client(id: $id) {\n      ...ClientItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateClient($id: ID!, $input: ClientInput!) {\n    updateClient(id: $id, input: $input) {\n      message\n      client {\n        ...ClientItem\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateClient($id: ID!, $input: ClientInput!) {\n    updateClient(id: $id, input: $input) {\n      message\n      client {\n        ...ClientItem\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateProjectControl($input: ProjectControlInput!) {\n    createProjectControl(input: $input) {\n      message\n      projectControl {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateProjectControl($input: ProjectControlInput!) {\n    createProjectControl(input: $input) {\n      message\n      projectControl {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateProject($input: ProjectInput!) {\n    createProject(input: $input) {\n      message\n      project {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateProject($input: ProjectInput!) {\n    createProject(input: $input) {\n      message\n      project {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateClient($input: ClientInput!) {\n    createClient(input: $input) {\n      message\n      client {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateClient($input: ClientInput!) {\n    createClient(input: $input) {\n      message\n      client {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ClientProjects($id: ID!) {\n    client(id: $id) {\n      id\n      name\n      ...ProjectsListProjects\n    }\n  }\n"): (typeof documents)["\n  query ClientProjects($id: ID!) {\n    client(id: $id) {\n      id\n      name\n      ...ProjectsListProjects\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query DashboardProjects($input: ProjectsConnectionInputArgs!) {\n    projects(input: $input) {\n      results {\n        id\n        publicRef\n        date\n        estimatedBenchmark\n        reportBenchmark\n        reportSavings\n        client {\n          id\n          name\n          status\n          user {\n            id\n            name\n          }\n        }\n        category {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query DashboardProjects($input: ProjectsConnectionInputArgs!) {\n    projects(input: $input) {\n      results {\n        id\n        publicRef\n        date\n        estimatedBenchmark\n        reportBenchmark\n        reportSavings\n        client {\n          id\n          name\n          status\n          user {\n            id\n            name\n          }\n        }\n        category {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Documents($input: ConnectionInputArgs!) {\n    documents(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        url\n        stage\n        createdAt\n        canEdit\n        category {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Documents($input: ConnectionInputArgs!) {\n    documents(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        url\n        stage\n        createdAt\n        canEdit\n        category {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteDocument($id: ID!) {\n    deleteDocument(id: $id) {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteDocument($id: ID!) {\n    deleteDocument(id: $id) {\n      message\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateDocument($input: DocumentInput!) {\n    createDocument(input: $input) {\n      message\n      document {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateDocument($input: DocumentInput!) {\n    createDocument(input: $input) {\n      message\n      document {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateInvoiceNotice($input: InvoiceNoticeInput!) {\n    createInvoiceNotice(input: $input) {\n      message\n      invoiceNotice {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateInvoiceNotice($input: InvoiceNoticeInput!) {\n    createInvoiceNotice(input: $input) {\n      message\n      invoiceNotice {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateInvoiceCredit($input: InvoiceInput!) {\n    createInvoice(input: $input) {\n      message\n      invoice {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateInvoiceCredit($input: InvoiceInput!) {\n    createInvoice(input: $input) {\n      message\n      invoice {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query EditInvoice($id: ID!) {\n    invoice(id: $id) {\n      id\n      issueDate\n      dueDate\n      sequenceNumber\n      description\n      client {\n        id\n      }\n      project {\n        id\n      }\n      projectControl {\n        id\n      }\n      recipient {\n        name\n        contactPerson\n        email\n        address {\n          streetAddress\n          postalCode\n          postalArea\n        }\n      }\n      items {\n        results {\n          id\n          description\n          unitPrice\n          quantity\n          vatRate\n          product {\n            id\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query EditInvoice($id: ID!) {\n    invoice(id: $id) {\n      id\n      issueDate\n      dueDate\n      sequenceNumber\n      description\n      client {\n        id\n      }\n      project {\n        id\n      }\n      projectControl {\n        id\n      }\n      recipient {\n        name\n        contactPerson\n        email\n        address {\n          streetAddress\n          postalCode\n          postalArea\n        }\n      }\n      items {\n        results {\n          id\n          description\n          unitPrice\n          quantity\n          vatRate\n          product {\n            id\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateInvoice($id: ID!, $input: InvoiceInput!) {\n    updateInvoice(id: $id, input: $input) {\n      message\n      invoice {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateInvoice($id: ID!, $input: InvoiceInput!) {\n    updateInvoice(id: $id, input: $input) {\n      message\n      invoice {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Invoice($id: ID!) {\n    invoice(id: $id) {\n      id\n      type\n      state\n      publicRef\n      issueDate\n      dueDate\n      sum\n      vat\n      total\n      description\n      canEdit\n      client {\n        id\n        name\n      }\n      recipient {\n        contactPerson\n        email\n        address {\n          streetAddress\n          postalCode\n          postalArea\n        }\n      }\n      items {\n        results {\n          id\n          description\n          unitPrice\n          quantity\n          vat\n        }\n      }\n      credit {\n        id\n      }\n      notices {\n        id\n        type\n      }\n    }\n  }\n"): (typeof documents)["\n  query Invoice($id: ID!) {\n    invoice(id: $id) {\n      id\n      type\n      state\n      publicRef\n      issueDate\n      dueDate\n      sum\n      vat\n      total\n      description\n      canEdit\n      client {\n        id\n        name\n      }\n      recipient {\n        contactPerson\n        email\n        address {\n          streetAddress\n          postalCode\n          postalArea\n        }\n      }\n      items {\n        results {\n          id\n          description\n          unitPrice\n          quantity\n          vat\n        }\n      }\n      credit {\n        id\n      }\n      notices {\n        id\n        type\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Invoices($input: ConnectionInputArgs) {\n    invoices(input: $input) {\n      totalCount\n      results {\n        id\n        publicRef\n        type\n        issueDate\n        sum\n        total\n        client {\n          id\n          name\n        }\n        project {\n          id\n          category {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Invoices($input: ConnectionInputArgs) {\n    invoices(input: $input) {\n      totalCount\n      results {\n        id\n        publicRef\n        type\n        issueDate\n        sum\n        total\n        client {\n          id\n          name\n        }\n        project {\n          id\n          category {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateInvoice($input: InvoiceInput!) {\n    createInvoice(input: $input) {\n      message\n      invoice {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateInvoice($input: InvoiceInput!) {\n    createInvoice(input: $input) {\n      message\n      invoice {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RequestOtp($input: OtpInput!) {\n    requestOtp(input: $input) {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation RequestOtp($input: OtpInput!) {\n    requestOtp(input: $input) {\n      message\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SignIn($input: SignInInput!) {\n    signIn(input: $input) {\n      message\n      viewer {\n        id\n        user {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SignIn($input: SignInInput!) {\n    signIn(input: $input) {\n      message\n      viewer {\n        id\n        user {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OfferCostSizes {\n    offerCostSizes {\n      results {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query OfferCostSizes {\n    offerCostSizes {\n      results {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateOffer($input: OfferInput!) {\n    createOffer(input: $input) {\n      message\n      offer {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateOffer($input: OfferInput!) {\n    createOffer(input: $input) {\n      message\n      offer {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Offer($id: ID!) {\n    offer(id: $id) {\n      id\n      date\n      supplier {\n        id\n        name\n      }\n      category {\n        id\n        name\n      }\n      costSize {\n        id\n        name\n      }\n      items {\n        results {\n          id\n          code\n          name\n          unit\n          unitPrice\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Offer($id: ID!) {\n    offer(id: $id) {\n      id\n      date\n      supplier {\n        id\n        name\n      }\n      category {\n        id\n        name\n      }\n      costSize {\n        id\n        name\n      }\n      items {\n        results {\n          id\n          code\n          name\n          unit\n          unitPrice\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Offers($input: ConnectionInputArgs!) {\n    offers(input: $input) {\n      totalCount\n      results {\n        id\n        date\n        supplier {\n          id\n          name\n        }\n        category {\n          id\n          name\n        }\n        costSize {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Offers($input: ConnectionInputArgs!) {\n    offers(input: $input) {\n      totalCount\n      results {\n        id\n        date\n        supplier {\n          id\n          name\n        }\n        category {\n          id\n          name\n        }\n        costSize {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment EditSpecialistItem on Specialist {\n    id\n    name\n    email\n    mobile\n    companyName\n    services\n    comment\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    categories {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment EditSpecialistItem on Specialist {\n    id\n    name\n    email\n    mobile\n    companyName\n    services\n    comment\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    categories {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query EditSpecialist($id: ID!) {\n    specialist(id: $id) {\n      ...EditSpecialistItem\n    }\n  }\n"): (typeof documents)["\n  query EditSpecialist($id: ID!) {\n    specialist(id: $id) {\n      ...EditSpecialistItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSpecialist($id: ID!, $input: SpecialistInput!) {\n    updateSpecialist(id: $id, input: $input) {\n      message\n      specialist {\n        ...EditSpecialistItem\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSpecialist($id: ID!, $input: SpecialistInput!) {\n    updateSpecialist(id: $id, input: $input) {\n      message\n      specialist {\n        ...EditSpecialistItem\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateSpecialist($input: SpecialistInput!) {\n    createSpecialist(input: $input) {\n      message\n      specialist {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSpecialist($input: SpecialistInput!) {\n    createSpecialist(input: $input) {\n      message\n      specialist {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Specialist($id: ID!) {\n    specialist(id: $id) {\n      id\n      name\n      email\n      mobile\n      companyName\n      services\n      comment\n      createdAt\n      updatedAt\n      canEdit\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n      categories {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query Specialist($id: ID!) {\n    specialist(id: $id) {\n      id\n      name\n      email\n      mobile\n      companyName\n      services\n      comment\n      createdAt\n      updatedAt\n      canEdit\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n      categories {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Specialists($input: ConnectionInputArgs!) {\n    specialists(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        mobile\n        email\n        categories {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Specialists($input: ConnectionInputArgs!) {\n    specialists(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        mobile\n        email\n        categories {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SupplierCompanyLookup($organizationNumber: String!) {\n    companyLookupFromSource(organizationNumber: $organizationNumber) {\n      id\n      name\n      organizationNumber\n      phone\n      email\n      employeesCount\n      employeesYear\n      revenuesYear\n      revenuesAmount\n      businessSector\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n    }\n  }\n"): (typeof documents)["\n  query SupplierCompanyLookup($organizationNumber: String!) {\n    companyLookupFromSource(organizationNumber: $organizationNumber) {\n      id\n      name\n      organizationNumber\n      phone\n      email\n      employeesCount\n      employeesYear\n      revenuesYear\n      revenuesAmount\n      businessSector\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SupplierItem on Supplier {\n    id\n    publicRef\n    rating\n    name\n    organizationNumber\n    email\n    phone\n    comment\n    employeesCount\n    employeesYear\n    revenuesYear\n    revenuesAmount\n    businessSector\n    createdAt\n    updatedAt\n    user {\n      id\n    }\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    contacts {\n      name\n      position\n      email\n      phone\n    }\n    categories {\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment SupplierItem on Supplier {\n    id\n    publicRef\n    rating\n    name\n    organizationNumber\n    email\n    phone\n    comment\n    employeesCount\n    employeesYear\n    revenuesYear\n    revenuesAmount\n    businessSector\n    createdAt\n    updatedAt\n    user {\n      id\n    }\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    contacts {\n      name\n      position\n      email\n      phone\n    }\n    categories {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query EditSupplier($id: ID!) {\n    supplier(id: $id) {\n      ...SupplierItem\n    }\n  }\n"): (typeof documents)["\n  query EditSupplier($id: ID!) {\n    supplier(id: $id) {\n      ...SupplierItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSupplier($id: ID!, $input: SupplierInput!) {\n    updateSupplier(id: $id, input: $input) {\n      message\n      supplier {\n        ...SupplierItem\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSupplier($id: ID!, $input: SupplierInput!) {\n    updateSupplier(id: $id, input: $input) {\n      message\n      supplier {\n        ...SupplierItem\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateSupplier($input: SupplierInput!) {\n    createSupplier(input: $input) {\n      message\n      supplier {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSupplier($input: SupplierInput!) {\n    createSupplier(input: $input) {\n      message\n      supplier {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Supplier($id: ID!) {\n    supplier(id: $id) {\n      id\n      publicRef\n      rating\n      name\n      organizationNumber\n      email\n      phone\n      comment\n      employeesCount\n      employeesYear\n      revenuesYear\n      revenuesAmount\n      businessSector\n      createdAt\n      updatedAt\n      canEdit\n      user {\n        id\n        name\n      }\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n      postAddress {\n        streetAddress\n        postalCode\n        postalArea\n      }\n      contacts {\n        name\n        position\n        email\n        phone\n      }\n      categories {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query Supplier($id: ID!) {\n    supplier(id: $id) {\n      id\n      publicRef\n      rating\n      name\n      organizationNumber\n      email\n      phone\n      comment\n      employeesCount\n      employeesYear\n      revenuesYear\n      revenuesAmount\n      businessSector\n      createdAt\n      updatedAt\n      canEdit\n      user {\n        id\n        name\n      }\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n      postAddress {\n        streetAddress\n        postalCode\n        postalArea\n      }\n      contacts {\n        name\n        position\n        email\n        phone\n      }\n      categories {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Suppliers($input: SuppliersConnectionInputArgs!) {\n    suppliers(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        publicRef\n        rating\n        address {\n          postalArea\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Suppliers($input: SuppliersConnectionInputArgs!) {\n    suppliers(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        publicRef\n        rating\n        address {\n          postalArea\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query EditUser($id: ID!) {\n    user(id: $id) {\n      id\n      name\n      email\n      mobile\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n    }\n  }\n"): (typeof documents)["\n  query EditUser($id: ID!) {\n    user(id: $id) {\n      id\n      name\n      email\n      mobile\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUser($id: ID!, $input: UserInput!) {\n    updateUser(id: $id, input: $input) {\n      message\n      user {\n        id\n        name\n        email\n        mobile\n        address {\n          streetAddress\n          postalCode\n          postalArea\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUser($id: ID!, $input: UserInput!) {\n    updateUser(id: $id, input: $input) {\n      message\n      user {\n        id\n        name\n        email\n        mobile\n        address {\n          streetAddress\n          postalCode\n          postalArea\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateUser($input: UserInput!) {\n    createUser(input: $input) {\n      message\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateUser($input: UserInput!) {\n    createUser(input: $input) {\n      message\n      user {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query User($id: ID!) {\n    user(id: $id) {\n      id\n      name\n      email\n      mobile\n      canEdit\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n    }\n  }\n"): (typeof documents)["\n  query User($id: ID!) {\n    user(id: $id) {\n      id\n      name\n      email\n      mobile\n      canEdit\n      address {\n        streetAddress\n        postalCode\n        postalArea\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeactivateUser($id: ID!) {\n    deactivateUser(id: $id) {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation DeactivateUser($id: ID!) {\n    deactivateUser(id: $id) {\n      message\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Users($input: ConnectionInputArgs!) {\n    users(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        email\n        mobile\n        address {\n          streetAddress\n          postalArea\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Users($input: ConnectionInputArgs!) {\n    users(input: $input) {\n      totalCount\n      results {\n        id\n        name\n        email\n        mobile\n        address {\n          streetAddress\n          postalArea\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;