import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { useMutation, useQuery } from '@apollo/client'
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import { graphql } from '@/__generated__/gql'
import { Drawer } from '@/components/drawer'
import { LoadingSection } from '@/components/loading-section'
import { ClientForm, FormValues } from './components/client-form'

export const clientItemFragmentDocument = graphql(`
  fragment ClientItem on Client {
    id
    publicRef
    status
    name
    organizationNumber
    email
    phone
    comment
    employeesCount
    employeesYear
    revenuesYear
    revenuesAmount
    businessSector
    createdAt
    updatedAt
    user {
      id
    }
    address {
      streetAddress
      postalCode
      postalArea
    }
    postAddress {
      streetAddress
      postalCode
      postalArea
    }
    contacts {
      name
      position
      email
      phone
    }
  }
`)

const editClientDocument = graphql(`
  query EditClient($id: ID!) {
    client(id: $id) {
      ...ClientItem
    }
  }
`)

const updateClientDocument = graphql(`
  mutation UpdateClient($id: ID!, $input: ClientInput!) {
    updateClient(id: $id, input: $input) {
      message
      client {
        ...ClientItem
      }
    }
  }
`)

export function EditClientRoute() {
  const params = z.object({ clientId: z.string() }).parse(useParams())
  const navigate = useNavigate()
  const toast = useToast()
  const onCancel = useCallback(
    () => navigate(`../${params.clientId}`),
    [navigate, params]
  )

  const { data, loading, error } = useQuery(editClientDocument, {
    variables: { id: params.clientId },
  })
  const [updateClient, { loading: isUpdating }] = useMutation(
    updateClientDocument,
    {
      onCompleted(data) {
        toast({
          status: 'success',
          title: data?.updateClient.message,
          isClosable: true,
        })
        onCancel()
      },
      onError(error) {
        toast({
          status: 'error',
          title: error.message,
          isClosable: true,
        })
      },
    }
  )

  const onSubmit = useCallback(
    async (input: FormValues) => {
      await updateClient({
        variables: {
          id: params.clientId,
          input: {
            ...input,
            postAddress: input.postAddress.streetAddress
              ? input.postAddress
              : null,
          },
        },
      })
    },
    [updateClient, params]
  )

  if (error) throw error

  const client = data?.client

  return (
    <Drawer
      size="lg"
      title="Endre kunde"
      onClose={() => navigate('..')}
      footer={
        <ButtonGroup>
          <Button onClick={onCancel}>Avbryt</Button>
          <Button
            form="client-form"
            type="submit"
            colorScheme="blue"
            isLoading={isUpdating}
          >
            Lagre kunde
          </Button>
        </ButtonGroup>
      }
    >
      {loading && <LoadingSection />}
      {!!client && (
        <ClientForm
          onSubmit={onSubmit}
          defaultValues={{
            name: client.name,
            organizationNumber: client.organizationNumber,
            userId: client.user?.id,
            email: client.email ?? undefined,
            phone: client.phone ?? undefined,
            comment: client.comment ?? undefined,
            employeesCount: client.employeesCount ?? undefined,
            employeesYear: client.employeesYear ?? undefined,
            revenuesYear: client.revenuesYear ?? undefined,
            revenuesAmount: client.revenuesAmount ?? undefined,
            businessSector: client.businessSector ?? undefined,
            address: {
              streetAddress: client.address.streetAddress,
              postalCode: client.address.postalCode,
              postalArea: client.address.postalArea,
            },
            postAddress: client.postAddress
              ? {
                  streetAddress: client.postAddress.streetAddress,
                  postalCode: client.postAddress.postalCode,
                  postalArea: client.postAddress.postalArea,
                }
              : undefined,
            contacts: client.contacts.map((contact) => ({
              name: contact.name,
              position: contact.position ?? undefined,
              email: contact.email ?? undefined,
              phone: contact.phone ?? undefined,
            })),
          }}
        />
      )}
    </Drawer>
  )
}
