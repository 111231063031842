import './fallback-error.scss'

type Props = {
  error?: unknown
}

export function FallbackError({ error }: Props) {
  console.error(error)
  return (
    <div className="fallback-error">
      <h1>Oops!</h1>
      <p>
        Sorry, an unexpected error has occurred. Plase refresh the page and try
        again.
      </p>
    </div>
  )
}
