import { useApolloClient } from '@apollo/client'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import {
  Button,
  FormLabel,
  FormControl,
  InputGroup,
  InputRightElement,
  useToast,
  Select,
} from '@chakra-ui/react'
import { MdOutlineSync } from 'react-icons/md'
import { graphql } from '@/__generated__/gql'
import { DeepPartial } from '@/types'
import { Row, Rows } from '@/components/rows'
import { Column, Columns } from '@/components/columns'
import { UserSelect } from '@/components/user-select'
import { Table, Thead, Tbody, Tr, Th, Td } from '@/components/table'
import { Input } from '@/components/input'
import { AddressFormFields } from '@/components/address-form-fields'
import { ActionsBar } from '@/components/actions-bar'
import { CategoriesSelect } from '@/components/categories-select'

type Props = {
  onSubmit: (input: FormValues) => Promise<void> | void
  defaultValues?: DeepPartial<FormValues>
}

export type FormValues = {
  userId: string | null
  rating: string
  name: string
  organizationNumber: string
  email: string
  phone: string
  comment: string
  employeesCount: number
  employeesYear: number
  revenuesYear: number
  revenuesAmount: number
  businessSector: string
  address: {
    streetAddress: string
    postalCode: string
    postalArea: string
  }
  postAddress: {
    streetAddress: string
    postalCode: string
    postalArea: string
  }
  contacts: {
    name: string
    position: string
    email: string
    phone: string
  }[]
  categories: { id: string }[]
}

const companyLookupDocument = graphql(`
  query SupplierCompanyLookup($organizationNumber: String!) {
    companyLookupFromSource(organizationNumber: $organizationNumber) {
      id
      name
      organizationNumber
      phone
      email
      employeesCount
      employeesYear
      revenuesYear
      revenuesAmount
      businessSector
      address {
        streetAddress
        postalCode
        postalArea
      }
    }
  }
`)

export function SupplierForm({ defaultValues, onSubmit }: Props) {
  const toast = useToast()
  const apolloClient = useApolloClient()
  const { register, control, handleSubmit, watch, setValue } =
    useForm<FormValues>({
      defaultValues,
    })
  const { fields, append, remove } = useFieldArray({
    keyName: '_id',
    control,
    name: 'contacts',
  })

  const organizationNumber = watch('organizationNumber')

  const syncFromOrganizationNumber = async () => {
    if (!organizationNumber) return
    const { data } = await apolloClient.query({
      query: companyLookupDocument,
      variables: { organizationNumber },
    })
    const company = data.companyLookupFromSource
    if (company) {
      setValue('name', company.name ?? '')
      setValue('phone', company.phone ?? '')
      setValue('email', company.email ?? '')
      if (company.employeesCount)
        setValue('employeesCount', company.employeesCount)
      if (company.employeesYear)
        setValue('employeesYear', company.employeesYear)
      if (company.revenuesAmount)
        setValue('revenuesAmount', company.revenuesAmount)
      if (company.revenuesYear) setValue('revenuesYear', company.revenuesYear)
      if (company.address)
        setValue('address', {
          streetAddress: company.address.streetAddress,
          postalCode: company.address.postalCode,
          postalArea: company.address.postalArea,
        })

      toast({
        status: 'success',
        title: 'Felter oppdatert med informasjon fra Brreg',
      })
    } else {
      toast({
        status: 'error',
        title: 'Fant ingen informasjon i Brreg',
      })
    }
  }

  return (
    <form id="supplier-form" onSubmit={handleSubmit(onSubmit)}>
      <Rows>
        <Row>
          <Columns gap={32}>
            <Column>
              <Rows>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>Org.nr.</FormLabel>
                    <InputGroup size="md">
                      <Input {...register('organizationNumber')} />
                      <InputRightElement width="4.5rem">
                        <Button
                          isDisabled={(organizationNumber || '').length < 9}
                          h="1.75rem"
                          size="sm"
                          onClick={syncFromOrganizationNumber}
                        >
                          <MdOutlineSync />
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Rating</FormLabel>
                    <Select {...register('rating', { required: true })}>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                    </Select>
                  </FormControl>
                </Row>
                <Row>
                  <FormControl isRequired>
                    <FormLabel>Firmanavn</FormLabel>
                    <Input {...register('name')} />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>E-post</FormLabel>
                    <Input {...register('email')} />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Telefon</FormLabel>
                    <Input {...register('phone')} />
                  </FormControl>
                </Row>
                <Row>
                  <AddressFormFields isRequired register={register} />
                </Row>
              </Rows>
            </Column>
            <Column>
              <Rows>
                <Row>
                  <FormControl>
                    <FormLabel>Rådgiver</FormLabel>
                    <Controller
                      control={control}
                      name="userId"
                      render={({ field: { value, onChange } }) => (
                        <UserSelect
                          prompt="Ingen (fri)"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Omsetning</FormLabel>
                    <Columns>
                      <Column>
                        <Input
                          placeholder="Kr"
                          {...register('revenuesAmount', {
                            valueAsNumber: true,
                          })}
                        />
                      </Column>
                      <Column>
                        <Input
                          placeholder="År"
                          {...register('revenuesYear', { valueAsNumber: true })}
                        />
                      </Column>
                    </Columns>
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Ansatte</FormLabel>
                    <Columns>
                      <Column>
                        <Input
                          placeholder="Kr"
                          {...register('employeesCount', {
                            valueAsNumber: true,
                          })}
                        />
                      </Column>
                      <Column>
                        <Input
                          placeholder="År"
                          {...register('employeesYear', {
                            valueAsNumber: true,
                          })}
                        />
                      </Column>
                    </Columns>
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Bransje</FormLabel>
                    <Input {...register('businessSector')} />
                  </FormControl>
                </Row>
                <Row>
                  <AddressFormFields
                    prefix="postAddress"
                    label="Postadresse"
                    register={register}
                  />
                </Row>
                <Row>
                  <FormControl>
                    <FormLabel>Kommentar</FormLabel>
                    <Input {...register('comment')} />
                  </FormControl>
                </Row>
              </Rows>
            </Column>
          </Columns>
        </Row>
        <Row>
          <Table>
            <Thead>
              <Tr>
                <Th>Kontaktperson</Th>
                <Th>Stilling</Th>
                <Th>E-post</Th>
                <Th>Telefon</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {fields.map((field, i) => (
                <Tr key={field._id}>
                  <Td>
                    <FormControl>
                      <Input
                        {...register(`contacts.${i}.name`, { required: true })}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl>
                      <Input {...register(`contacts.${i}.position`)} />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl>
                      <Input {...register(`contacts.${i}.email`)} />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl>
                      <Input {...register(`contacts.${i}.phone`)} />
                    </FormControl>
                  </Td>
                  <Td>
                    <Button size="sm" onClick={() => remove(i)}>
                      x
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Row>
        <Row>
          <ActionsBar center>
            <Button
              size="sm"
              variant="outline"
              onClick={() =>
                append({ name: '', position: '', email: '', phone: '' })
              }
            >
              Legg til kontaktperson +
            </Button>
          </ActionsBar>
        </Row>
        <Row>
          <FormControl>
            <FormLabel>Kategorier</FormLabel>
            <Controller
              control={control}
              name="categories"
              render={({ field: { value, onChange } }) => (
                <CategoriesSelect
                  values={value?.map(({ id }) => id) || []}
                  onChange={(ids) => onChange(ids.map((id) => ({ id })))}
                />
              )}
            />
          </FormControl>
        </Row>
      </Rows>
    </form>
  )
}
