import { Button } from '@chakra-ui/react'
import { config } from '@/config'

type Props = {
  file:
    | 'projects.xlsx'
    | 'clients.xlsx'
    | 'suppliers.xlsx'
    | 'invoices.xlsx'
    | 'income-estimate.xlsx'
  label: React.ReactNode
}

export function DownloadExportButton({ file, label }: Props) {
  const handleClick = () => {
    window.location.href = `${config.exportUrl}/${file}`
  }

  return (
    <Button size="sm" variant="ghost" onClick={handleClick}>
      {label}
    </Button>
  )
}
