import { Controller, useForm } from 'react-hook-form'
import { FormLabel, FormControl } from '@chakra-ui/react'
import { Row, Rows } from '@/components/rows'
import { NumberInput } from '@/components/number-input'
import { DateInput } from '@/components/date-input'

type Props = {
  onSubmit: (input: FormValues) => Promise<void> | void
  defaultValues?: Partial<FormValues>
}

export type FormValues = {
  fromDate: string
  toDate: string
  feeInPercentage: number
  savings: number
}

export function ProjectControlForm({ defaultValues, onSubmit }: Props) {
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues,
  })

  return (
    <form id="project-control-form" onSubmit={handleSubmit(onSubmit)}>
      <Rows>
        <Row>
          <FormControl isRequired>
            <FormLabel>Fra dato</FormLabel>
            <Controller
              control={control}
              name="fromDate"
              rules={{ required: true }}
              render={({ field }) => (
                <DateInput
                  value={field.value}
                  onChange={(v) => field.onChange(v ?? '')}
                />
              )}
            />
          </FormControl>
        </Row>
        <Row>
          <FormControl isRequired>
            <FormLabel>Til dato</FormLabel>
            <Controller
              control={control}
              name="toDate"
              rules={{ required: true }}
              render={({ field }) => (
                <DateInput
                  value={field.value}
                  onChange={(v) => field.onChange(v ?? '')}
                />
              )}
            />
          </FormControl>
        </Row>
        <Row>
          <FormControl isRequired>
            <FormLabel>Besparelse i kr</FormLabel>
            <Controller
              control={control}
              name="savings"
              rules={{ required: true }}
              render={({ field }) => (
                <NumberInput
                  value={field.value}
                  onChange={(v) => field.onChange(v ?? 0)}
                />
              )}
            />
          </FormControl>
        </Row>
        <Row>
          <FormControl isRequired>
            <FormLabel>Honorar i %</FormLabel>
            <Controller
              control={control}
              name="feeInPercentage"
              rules={{ required: true }}
              render={({ field }) => (
                <NumberInput
                  precision={1}
                  max={100}
                  value={field.value}
                  onChange={(v) => field.onChange(v ?? 0)}
                />
              )}
            />
          </FormControl>
        </Row>
      </Rows>
    </form>
  )
}
