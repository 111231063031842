import { Controller, useForm } from 'react-hook-form'
import { FormLabel, FormControl, Select } from '@chakra-ui/react'
import { Input } from '@/components/input'
import { Row, Rows } from '@/components/rows'
import { CategorySelect } from '@/components/category-select'
import { FileUploadInput } from '@/components/file-upload-input'

type Props = {
  onSubmit: (input: FormValues) => Promise<void> | void
  defaultValues?: Partial<FormValues>
}

export type FormValues = {
  url: string
  name: string
  categoryId: string | null
  stage: string | null
  position: number | null
}

export const documentStages: { [k: string]: string } = {
  prospect_phase: 'Prosjektfase',
  cooperation: 'Samarbeidsavtale',
  data_collection: 'Datainnhenting',
  analysis: 'Analyse',
  analysis_meeting: 'Analysemøte',
  tender_preocess: 'Anbudsprosess',
  results_report: 'Resultatrapport',
  implementing_control: 'Implementering og etterkontroll',
}

export function DocumentForm({ defaultValues, onSubmit }: Props) {
  const { register, control, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues,
  })

  return (
    <form id="document-form" onSubmit={handleSubmit(onSubmit)}>
      <Rows>
        <Row>
          <FormControl isRequired>
            <FormLabel>Fil</FormLabel>
            <Controller
              control={control}
              name="url"
              rules={{ required: true }}
              render={({ field }) => (
                <FileUploadInput
                  onFilename={(filename) => setValue('name', filename)}
                  value={field.value}
                  onChange={(v) => field.onChange(v ?? '')}
                />
              )}
            />
          </FormControl>
        </Row>
        <Row>
          <FormControl isRequired>
            <FormLabel>Filnavn</FormLabel>
            <Input {...register('name', { required: true })} />
          </FormControl>
        </Row>
        <Row>
          <FormControl>
            <FormLabel>Prosesstrinn</FormLabel>
            <Controller
              control={control}
              name="stage"
              render={({ field }) => (
                <Select
                  value={field.value ?? ''}
                  onChange={(e) => field.onChange(e.target.value || null)}
                >
                  <option>Ingen prosesstrinn</option>
                  {Object.entries(documentStages).map(([value, label]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Row>
        <Row>
          <FormControl>
            <FormLabel>Kategori</FormLabel>
            <Controller
              control={control}
              name="categoryId"
              render={({ field }) => (
                <CategorySelect
                  prompt="Ingen kategori"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FormControl>
        </Row>
      </Rows>
    </form>
  )
}
